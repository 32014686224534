import ApplicationRecord from './application-record/Home';
import ApprovalCenter from './approval-center/Home';
import recordDetail from './application-record/Detail'
import centerDetail from './approval-center/Detail'
const routers = [
  // 客户
  {
    path: '/app/application-record/home',
    component: ApplicationRecord
  },
  {
    path: '/app/application-record/:applyId/detail',
    component: recordDetail
  },
  // 合同客户: 添加
  {
    path: '/app/approval-center/home',
    component: ApprovalCenter
  },
  {
    path: '/app/application-center/:applyId/detail',
    component: centerDetail
  },
];

export default routers;