/**
 * 面包屑组件
 * props：需要传入routes参数
 interface Route {
  path: string;
  breadcrumbName: string;
  children: Array<{
    path: string;
    breadcrumbName: string;
  }>;
}
*/
import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Breadcrumb } from 'antd';

const RouteBreadcrumb = (props) => {
  const { routes } = props;

  const itemRender = (route, params, routes, paths) => {
    // console.log('Breadcrumb route, params, routes, paths ===', route, params, routes, paths);
    const last = routes.indexOf(route) === routes.length - 1;
    const _link = {
      pathname: route.path,
      params: route.params,
      state: route.state,
      search: route.search
    }
    return last ? (
      <span>{route.breadcrumbName}</span>
    ) : (
      <Link to={_link}>{route.breadcrumbName}</Link>
    );
  }

  return <Breadcrumb className="page-router" itemRender={itemRender} routes={routes} />;
};

export default RouteBreadcrumb;