import React, { useEffect, useState, useContext } from "react";
import { useHistory } from "react-router-dom";
import { GlobalContext } from "../view/reducer";
import { Layout, Menu } from 'antd';
import LangMenu from "./component/LangMenu";
import { COPYRIGHT } from "../libs/configData";
import { getLocale } from "../libs/utils";
import 'src/scss/login.scss';

const { Header, Content, Footer } = Layout;
const OutlinkLayout = ({ children }) => {
  const history = useHistory();
  const { userLocale } = useContext(GlobalContext);
  const [logoStyle, setLogoStyle] = useState('logo-en');

  // 未登录框架 缓存语言：如果本地未缓存语言，则缓存语言；
  const cacheLocale = () => {
    const _locale = $storage.get('locale');
    if (!_locale) {
      const _locale_ = userLocale || getLocale()
      $storage.set('locale', _locale_);
    }
  }
  cacheLocale();

  useEffect(() => {
    // logo 国际化
    const locale = userLocale || getLocale();
    // locale === 'zh-CN' ? setLogoStyle('logo-cn') : setLogoStyle('logo-cn');
    switch (locale) {
      case "zh-CN":
        setLogoStyle('logo-cn')
        break;
      case "en":
        setLogoStyle('logo-en')
        break;
      case "ja-JP":
        setLogoStyle('logo-jp')
        break;
      default:
        setLogoStyle('logo-cn')
        break;
    }
  }, []);

  const gotoLogin = () => {
    history.push({pathname: '/login'});
  }

  return (
      <Layout className='main-container'>
        <Header className="header-blue">
          <div className={logoStyle} />
          <Menu
            theme="dark"
            mode="horizontal"
            className="menu-blue menu-right"
          >
            {/* <Menu.Item key="Home">Home</Menu.Item> */}
            <LangMenu />
            <Menu.Item key="sub_user-login" onClick={gotoLogin}>{lang.登录}</Menu.Item>
          </Menu>
        </Header>
        <Content
          className="site-layout"
          style={{
            padding: "1rem",
            margin: "0",
            height: "100%",
            overflow: "scroll"
          }}
        >
          {children}
        <Footer className="login-footer">{COPYRIGHT} {lang.上海高仙自动化科技发展有限公司}</Footer>
        </Content>
      </Layout>
  )
}

export default OutlinkLayout;