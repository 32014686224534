import { notification } from 'antd';
import { getToken, refreshToken, isAccessTokenExpired, ssoLogin } from '../libs/utils';

/**
 * @http错误 信息提示：notification
**/
let errTimer = null;
const msg = (type, context) => {
  clearTimeout(errTimer);
  errTimer = setTimeout(() => {
    notification[type]({
      message: context,
      description: '',
    });
  }, 500);
}

// 无token 请求拦截
const apiInterceptWithoutToken = (http) => {

  http.axios.interceptors.request.use(
    config => {
      const { url } = config;
      // 来自第三方应用，增加 withCredentials 配置
      if (url.indexOf('ticket') !== -1 && !(url.indexOf('mode') !== -1)) {
        config.withCredentials = true;
      }
      return config;
    },
    request => request,
    error => error,
  );

  http.axios.interceptors.response.use(
    response => response,
    error => responseInstance(error),
  );
}

const apiIntercept = (http) => {
  /**
   * iam axios request 拦截 
   * 1.设置登录态token: Bearer
   * 2.post Content-Type = 'application/json'
   */
  http.axios.interceptors.request.use(
    request => requestInstance(request),
    error => error,
  );
  /**
   * iam axios response 拦截 
   */
  http.axios.interceptors.response.use(
    response => response,
    error => responseInstance(error),
  );
}

// 请求拦截
function requestInstance(request) {
  const { method, url } = request;
  const token = getToken(); // auth

  if (["post", "delete", "put", "get"].includes(method)) {
    request.headers["Content-Type"] = "application/json";
  }
  
  if (token) {
    if (isAccessTokenExpired()) {
      refreshToken();
    } else {
      request.headers.Authorization = `Bearer ${token}`;
    }
    return request;
  } else {
    ssoLogin();
  }
}

// 响应拦截
function responseInstance(error) {
  let response = { };
  // response 整个对象
  if (error.response) {
    response = error.response;

    const { status } = response;
    switch (status) {
      case 400:
        break;

      case 401:
        // 刷新token
        refreshToken();
        break;
        
      case 403:
        msg('error', (lang?.无访问权限 || 'No access'));
        break;

      case 404:
        msg('error', (lang?.请求错误 || 'Resource not found'));
        break;

      case 501:
      case 500:
        msg('error', (lang?.服务异常 || 'Service Error'));
        break;

      case 503:
        msg('error', (lang?.服务不可用 || 'Service Unavailable'));
        break;

      case 504:
        msg('error', (lang?.网关超时 || 'Gateway timeout'));
        break;

      default:
        msg('error', (lang?.其他错误 || 'Other errors'));
    }

  } else {
    // axios 全局设置的超时拦截
    if (error.config) {
      response.config = error.config;
      if (error.message.includes('timeout')) {
        msg('error', (lang?.请求超时 || 'Time out'));
      } else {
        msg('error', (lang?.其他错误 || 'Other errors'));
      }
    } else {
      msg('error', error);
    }
  }

  // 接口错误前端模拟错误供每个页面接口捕获
  throw response;
}

export {
  apiIntercept,
  apiInterceptWithoutToken
}
