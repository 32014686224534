import { Card, Tabs } from 'antd';
import RouteBreadcrumb from "src/component/RouteBreadcrumb";
import ApplicationList from './ApplicationList';

const ApplicationRecord = () => {

    const breadCrumb = [
        {
            path: "",
            breadcrumbName: lang.授权管理,
        },
        {
            path: "",
            breadcrumbName: lang.申请记录,
        },
    ]

    return <div>
        <RouteBreadcrumb routes={breadCrumb}></RouteBreadcrumb>
        <Card>
            <ApplicationList></ApplicationList>
        </Card>
    </div>
}

export default ApplicationRecord;