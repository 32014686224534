import React, { useState, useEffect, useContext } from "react";
import { Tooltip, Image } from "antd";
import { useHistory } from "react-router-dom";
import { fontOverflow, getToken } from "src/libs/utils";
import { GlobalContext } from "src/view/reducer";
import { SERVICES } from "src/libs/configData";
import robotSvg from "assets/images/robot.svg";
import dataCenterSvg from "assets/images/data-center.svg";
import iamSvg from "assets/images/iam.svg";

const Brand = (props) => {
  const { setIsProfileEnable } = props;
  const history = useHistory();
  const token = getToken();
  const ownContext = $session.sessionget("view.layout.own.context");
  // 当前租户
  const { tenant, applicationPermissions, user } = useContext(GlobalContext);
  const [displayName, setDisplayName] = useState(lang.应用中心);
  // 是否切回本公司
  const [isOwnTenantVisible, setIsOwnTenantVisible] = useState(true);

  useEffect(
    () => {
      if (token) {
        // setUpdateMe(true);
        const authTenants = $session.sessionget("view.layout.auth.tenants");
        if (!authTenants) {
          setIsProfileEnable(true);
          setIsOwnTenantVisible(true);
        }
      }
    },
    [token]
  );

  useEffect(
    () => {
      let _displayName = "";
      if (ownContext?.tenant) {
        _displayName = ownContext.tenant.displayName;
      } else {
        _displayName = tenant.displayName;
      }
      setDisplayName(_displayName);
    },
    [tenant, ownContext]
  );

  const toPlatform = (platform) => {
    const { iam, report, gsCloud } = SERVICES;
    switch (platform) {
      case 'cloud':
        {
          const { href } = gsCloud;
          window.location.href = `${href}`;
        }
        break;
      case 'app':
        {
          $storage.set("layout.menu.active", 'user_all');
          history.push({ pathname: "/app/center/home" });
        }
        break;
      case 'report':
        {
          const { href } = report;
          window.location.href = `${href}`;
        }
        break;
      case 'iam':
        {
          const { href } = iam;
          window.location.href = `${href}`;
        }
        break;
      default:
        break
    }
  }

  return (
    <>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div className={isOwnTenantVisible ? "brand" : "brand-multi"} style={{ minWidth: 200 }}>
          <div className={isOwnTenantVisible ? null : "title-main"}>
            <Tooltip title={displayName}>
              <span className="title">{fontOverflow(displayName, 30)}</span>
            </Tooltip>
          </div>
        </div>
        <div className="brand-platform">
          <span onClick={() => toPlatform('cloud')}>
            <Image src={robotSvg} preview={false}></Image>
            <div>{lang.机器人平台}</div>
          </span>
          {
            applicationPermissions.includes('DataOpsPlatform') ? <span onClick={() => toPlatform('report')}>
              <Image src={dataCenterSvg} preview={false}></Image>
              {lang.数据中心}</span> : <></>
          }
          {
            applicationPermissions.includes('IAM') ? <span onClick={() => toPlatform('iam')}>
              <Image src={iamSvg} preview={false}></Image>
              IAM
            </span> : null
          }
        </div>
      </div>
    </>
  );
};

export default Brand;
