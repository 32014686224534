import Login from "../view/login/_router";
import Center from "../view/app-center/_router";
import Agreements from "../view/agreements/_router";

import PageErr from "../view/PageErr";
import GSAgreements from "../view/GSAgreements";
import Authorization from "../view/authorization/_router";
// import LeaseList from "../view/lease-manage/LeaseList";

export const loginRoutes = [
  ...Login,
  ...Agreements,
  {
    path: "/user-privacy-agreement",
    component: GSAgreements,
  },
  {
    path: "/404",
    component: PageErr,
  },
];

export const mainRoutes = [
  ...Center,
];
