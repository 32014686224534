import { useEffect, useState, useRef, useContext } from "react";
import {
    Table, Form, Input, Button, Radio, Row,
    Col, DatePicker, Space, Modal, Typography,
    message
} from 'antd';
import { useHistory } from "react-router-dom";
import moment from 'moment';
import { GlobalContext } from "src/view/reducer";
import { machineAuthorizationApi } from "../_api";
import { getTimeStampsForLastNDays } from "src/libs/utils";
import '../style.scss';
import { ERROR_CODE_MAP } from "src/constants/errorCode";
import { dateFormat } from "src/libs/utils";

const { Title, Text } = Typography

const ApprovalCmp = (props) => {
    const { setTotal: setTabTotal, applyStates, applyType, tabTotal, getUnderReviewCount } = props;
    const { customer } = useContext(GlobalContext);
    const [formSearch] = Form.useForm();
    const [formModal] = Form.useForm();
    const history = useHistory();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [applyLoading, setApplyLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [recordList, setRecordList] = useState([]);
    const [batch, setBatch] = useState(false); // 是否批量处理
    const initialPaginantion = {
        pageNumber: 1,
        pageSize: 10,
    };
    const paginationRef = useRef({ ...initialPaginantion });
    const authItemRef = useRef({});
    const auditReason = Form.useWatch('auditReason', formModal);
    console.log('auditReason', auditReason)
    const initialValues = {
        quickDay: 7,
        date: undefined,
        applyPersonUsername: undefined,
        applyPersonCustomerName: undefined,
        robotSn: undefined,
        robotOwnerCustomerName: undefined,
    }

    const search = () => {
        paginationRef.current = { ...initialPaginantion };
        fetchRecordList();
    }

    const reset = () => {
        formSearch.resetFields();
        search();
    };

    const onDateChange = () => {
        formSearch.setFieldsValue({
            quickDay: null
        });
        search();
    }

    const onQuickDayChange = () => {
        formSearch.setFieldsValue({
            date: null
        });
        search();
    }

    useEffect(() => {
        if (customer?.id && applyType) {
            fetchRecordList()
        }
    }, [customer, applyType])

    const onSelectChange = (newSelectedRowKeys, selectedRows) => {
        console.log('selectedRowKeys changed: ', newSelectedRowKeys, selectedRows);
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys,
        preserveSelectedRowKeys: true,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: lang.申请时间,
            dataIndex: 'applyTime',
            render: (applyTime) => dateFormat.formatUnixTimeStamp(applyTime)
        },
        {
            title: lang.申请账号,
            dataIndex: 'applyPersonUsername',
        },
        {
            title: lang.申请人所属客户,
            dataIndex: 'applyPersonCustomerName',
        },
        {
            title: lang.机器SN,
            dataIndex: 'robotSn',
        },
        {
            title: lang.申请原因,
            dataIndex: 'applyReason',
        },
        {
            title: lang.操作,
            render: (_, record) =>
                <div>
                    <a style={{ marginRight: 6 }} onClick={() => dealAuthItem(record)}>{lang.处理}</a>
                    <a onClick={() => goDetail(record)}>{lang.详情}</a>
                </div>
        },
    ]

    const goDetail = (record) => {
        history.push({
            pathname: `/app/application-center/${record.applyId}/detail`,
            state: {
                customer: record,
            },
        })
    }

    const dealAuthItem = (record) => {
        setBatch(false);
        setOpen(true);
        authItemRef.current = record
    }

    const onTableChange = ({ current, pageSize }) => {
        paginationRef.current = {
            pageNumber: current,
            pageSize,
        };
        fetchRecordList();
    };

    const fetchRecordList = async () => {
        setLoading(true);
        const { date, quickDay, ...rest } = formSearch.getFieldsValue();
        let startApplyTime = '', endApplyTime = '';
        if (date) {
            const startOfQuarter = moment(date).startOf('year');
            // 获取所选季度的结束时间
            const endOfQuarter = moment(date).endOf('year');
            // 获取时间戳（毫秒数）
            const startTimestamp = startOfQuarter.valueOf();
            const endTimestamp = endOfQuarter.valueOf();
            startApplyTime = startTimestamp;
            endApplyTime = endTimestamp;
        } else {
            const { startTimestamp, endTimestamp } = getTimeStampsForLastNDays(quickDay)
            startApplyTime = startTimestamp;
            endApplyTime = endTimestamp;
        };
        try {
            const resp = await machineAuthorizationApi.robotAuthApplys({
                ...paginationRef.current,
                ...rest,
                startApplyTime,
                endApplyTime,
                customerId: customer?.id,
                applyStates
            });
            setRecordList(resp?.data?.applications);
            setTotal(resp?.data?.totalSize);
            setTabTotal({
                ...tabTotal,
                [applyType + 'Total']: resp?.data?.totalSize
            });
        } catch {

        } finally {
            setLoading(false)
        }
    }

    const batchProcess = () => {
        setBatch(true);
        setOpen(true);
    }

    const operateAuthorize = async (type) => {
        const resp = await machineAuthorizationApi.batchApproveAuthApply({
            applyIds: batch ? selectedRowKeys : [authItemRef.current.applyId],
            applyState: type === 'authorized' ? 'MANUAL_APPROVED' : 'MANUAL_REJECTED',
            ...formModal.getFieldsValue()
        });

        if (!resp?.code) {
            message.success(lang.操作成功);
            setOpen(false);
            formModal.resetFields();
            setSelectedRowKeys([])
            search();

            if (applyType === 'pending') {
                getUnderReviewCount();
            }
        } else {
            message.error(lang[ERROR_CODE_MAP[resp?.code]] || lang.操作失败)
        }
    }

    return <div>
        <Form
            form={formSearch}
            initialValues={initialValues}
        >
            <Row gutter={12}>
                <Space>
                    <Form.Item name="date">
                        <DatePicker picker='year' onChange={onDateChange}></DatePicker>
                    </Form.Item>
                    <Form.Item
                        name="quickDay"
                    >
                        <Radio.Group defaultValue={7} buttonStyle="solid" onChange={onQuickDayChange}>
                            <Radio.Button value={7}>{lang.近7天}</Radio.Button>
                            <Radio.Button value={30}>{lang.近30天}</Radio.Button>
                            <Radio.Button value={90}>{lang.近90天}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Space>
            </Row>
            <Row gutter={12}>
                <Col span={6}>
                    <Form.Item
                        name='applyPersonUsername'
                        label={lang.申请账号}
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='applyPersonCustomerName'
                        label={lang.申请人所属客户}
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='robotSn'
                        label={lang.机器SN}
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <Space size="small">
                        <Button
                            htmlType="submit"
                            type="primary"
                            ghost
                            onClick={search}
                        >
                            {lang.查询}
                        </Button>
                        <Button onClick={() => reset()}>{lang.重置}</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
        <div className={'flex-jb-ac'}>
            <span>
                {window["__lang"]("selectedItem", selectedRowKeys.length)}
                {/* 已选择{selectedRowKeys.length}条数据 */}
            </span>
            <Button onClick={batchProcess} disabled={!selectedRowKeys.length}>{lang.批量处理}</Button>
        </div>
        <Table
            columns={columns}
            dataSource={recordList}
            loading={loading}
            onChange={onTableChange}
            rowSelection={rowSelection}
            pagination={{
                current: paginationRef.current.pageNumber,
                pageSize: paginationRef.current.pageSize,
                total
            }}
            rowKey="applyId"
        >
        </Table>
        <Modal
            title={batch ? lang.批量处理 : lang.处理}
            footer={
                [
                    applyType !== 'rejected' ? <Button disabled={!auditReason} danger onClick={() => operateAuthorize('rejected')}>{lang.拒绝授权}</Button> : null,
                    applyType !== 'authorized' ? <Button disabled={!auditReason} type='primary' onClick={() => operateAuthorize('authorized')}>{lang.同意授权}</Button> : null
                ]
            }
            open={open}
            onCancel={() => {
                setOpen(false);
                formModal.resetFields();
            }}
            width={600}
        >
            <Row gutter={24}>
                <Col span={12}>
                    <Space direction='vertical'>
                        <Title level={5}>{lang.approvalTitle}</Title>
                        <Text>{lang.approvalContentOne}</Text>
                        <Text>{lang.approvalContentTwo}</Text>
                        <Text>{lang.approvalContentThree}</Text>
                    </Space>
                </Col>
                <Col span={12}>
                    <Form
                        form={formModal}
                        layout='vertical'
                    >
                        <Form.Item
                            label={
                                <div>
                                    <span className="auth-count-red">*</span>
                                    <span>{lang.处理意见}</span>
                                </div>
                            }
                            rules={[
                                {
                                    required: true
                                }
                            ]}
                        >
                            <div style={{ marginBottom: 8 }} dangerouslySetInnerHTML={{ __html: window["__lang"]("dealItems", batch ? selectedRowKeys.length : 1, { className: 'auth-count-red' }) }}></div>
                            <Form.Item
                                name="auditReason"
                                rules={[
                                    {
                                        required: true
                                    }
                                ]}
                            >
                                <Input.TextArea
                                    showCount
                                    maxLength={300}
                                    style={{ height: 150, resize: "none" }}
                                ></Input.TextArea>
                            </Form.Item>
                        </Form.Item>
                    </Form>
                </Col>
            </Row>
        </Modal>
    </div>
}

export default ApprovalCmp;