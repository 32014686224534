import React from 'react';
import { useHistory } from "react-router-dom";
import { Result, Button } from 'antd';
import { logout } from "../libs/utils";

const PageErr = () => {
  const history = useHistory();
  
  const gotoLogin = () => {
    logout();
  }

  return (
    <Result
      status="404"
      title="404"
      subTitle={lang.页面找不到了}
      extra={<Button type="primary" onClick={gotoLogin}>{lang.返回登录}</Button>}
    />
  );
};

export default PageErr;