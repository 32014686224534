import { Card, Tabs } from 'antd';
import { useEffect, useState, useContext } from 'react';
import { GlobalContext } from "src/view/reducer";
import RouteBreadcrumb from "src/component/RouteBreadcrumb";
import ApprovalCmp from './ApprovalCmp';
import { machineAuthorizationApi } from '../_api';

const ApprovalCenter = () => {
    const { customer } = useContext(GlobalContext);
    const [total, setTotal] = useState({
        pendingTotal: 0,
        authorizedTotal: 0,
        rejectedTotal: 0
    });
    const [count, setCount] = useState(0);

    const [activeKey, setActiveKey] = useState('pending')
    const breadCrumb = [
        {
            path: "",
            breadcrumbName: lang.授权管理,
        },
        {
            path: "",
            breadcrumbName: lang.审批中心,
        },
    ]

    const getUnderReviewCount = async () => {
        const res = await machineAuthorizationApi.underReviewCount({
            customerId: customer?.id,
        });
        setCount(res?.data?.totalSize)
    }

    useEffect(() => {
        if (customer?.id) {
            getUnderReviewCount();
          }
    }, [customer])

    return <div>
        <RouteBreadcrumb routes={breadCrumb}></RouteBreadcrumb>
        <Card>
            <Tabs activeKey={activeKey} onChange={setActiveKey}>
                <Tabs.TabPane tab={`${lang.待处理}${(count > 99 ? '(99+)' : `(${count})`)}`} key="pending">
                    {
                        activeKey === 'pending' && <ApprovalCmp {
                            ...{
                                setTotal,
                                applyStates: ['UNDER_REVIEW'],
                                applyType: activeKey,
                                tabTotal: total,
                                getUnderReviewCount: getUnderReviewCount
                            }
                        }></ApprovalCmp>
                    }
                </Tabs.TabPane>
                <Tabs.TabPane tab={`${lang.已通过}`} key="authorized">
                    {
                        activeKey === 'authorized' && <ApprovalCmp
                            {
                            ...{
                                setTotal,
                                applyStates: ['AUTO_APPROVED', 'MANUAL_APPROVED'],
                                applyType: activeKey,
                                tabTotal: total,
                            }
                            }>
                        </ApprovalCmp>
                    }

                </Tabs.TabPane>
                <Tabs.TabPane tab={`${lang.已拒绝}`} key="rejected">
                    {
                        activeKey === 'rejected' && <ApprovalCmp
                            {
                            ...{
                                setTotal,
                                tabTotal: total,
                                applyType: activeKey,
                                applyStates: ['MANUAL_REJECTED']
                            }
                            }
                        ></ApprovalCmp>
                    }
                </Tabs.TabPane>
            </Tabs>
        </Card>
    </div>
}

export default ApprovalCenter;