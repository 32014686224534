// 异步加载组件
import { asyncComponent } from 'react-async-component';

const UserAgreement = asyncComponent({resolve: () => import("./UserAgreement")});
const PrivacyAgreement = asyncComponent({resolve: () => import("./PrivacyAgreement")});

const routers = [
  {
    path: '/areements/user-agreement',
    component: UserAgreement
  },
  {
    path: '/areements/privacy-agreement',
    component: PrivacyAgreement
  }
];

export default routers;