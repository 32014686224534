import {
    Table, Form, Input, Button, Radio,
    Row, Col, DatePicker, Space, Select
} from 'antd';
import { useState, useRef, useEffect, useContext } from 'react';
import moment from 'moment';
import { GlobalContext } from "src/view/reducer";
import { useHistory } from "react-router-dom";
import { machineAuthorizationApi } from '../_api';
import { APPLY_STATUS } from 'src/constants';
import { getTimeStampsForLastNDays } from 'src/libs/utils';
import { renderColor } from 'src/utils/utils';
import { dateFormat } from 'src/libs/utils';

const ApplicationList = () => {
    const { customer } = useContext(GlobalContext);
    const [form] = Form.useForm();
    const history = useHistory();
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);
    const [recordList, setRecordList] = useState([]);
    const initialPaginantion = {
        pageNumber: 1,
        pageSize: 10,
    };
    const paginationRef = useRef({ ...initialPaginantion });
    const initialValues = {
        quickDay: 7,
        date: undefined,
        applyPersonUsername: undefined,
        applyPersonCustomerName: undefined,
        robotSn: undefined,
        robotOwnerCustomerName: undefined,
        applyStates: [],
    }
    const columns = [
        {
            title: lang.申请时间,
            dataIndex: "applyTime",
            render: (applyTime) => dateFormat.formatUnixTimeStamp(applyTime)
        },
        {
            title: lang.申请账号,
            dataIndex: "applyPersonUsername",
        },
        {
            title: lang.申请人所属客户,
            dataIndex: "applyPersonCustomerName",
        },
        {
            title: lang.机器SN,
            dataIndex: "robotSn",
        },
        {
            title: lang.机器所属客户,
            dataIndex: "robotOwnerCustomerName",
        },
        {
            title: lang.申请原因,
            dataIndex: "applyReason",
            ellipsis: true,
            width: 150,
        },
        {
            title: lang.最新处理时间,
            dataIndex: "updateTime",
            render: (updateTime) => dateFormat.formatUnixTimeStamp(updateTime)
        },
        {
            title: lang.授权状态,
            dataIndex: "applyState",
            render: (applyState) => <div style={{ color: renderColor(applyState) }}>{lang[APPLY_STATUS[applyState]] || '-'}</div>
        },
        {
            title: lang.操作,
            render: (_, record) => <a onClick={() => goDetail(record)}>{lang.详情}</a>
        },
    ];

    const fetchRecordList = async () => {
        // setLoading(true);
        const { date, quickDay, ...rest} = form.getFieldsValue();
        let startApplyTime = '', endApplyTime	 = '';
        if (date) {
            const startOfQuarter = moment(date).startOf('quarter');
            // 获取所选季度的结束时间
            const endOfQuarter = moment(date).endOf('quarter');
            // 获取时间戳（毫秒数）
            const startTimestamp = startOfQuarter.valueOf();
            const endTimestamp = endOfQuarter.valueOf();
            startApplyTime = startTimestamp;
            endApplyTime = endTimestamp;
        } else {
            const { startTimestamp, endTimestamp } = getTimeStampsForLastNDays(quickDay)
            startApplyTime = startTimestamp;
            endApplyTime = endTimestamp;
        };
        console.log(startApplyTime, endApplyTime)
        const resp = await machineAuthorizationApi.recordApplys({
            ...paginationRef.current,
            ...rest,
            startApplyTime,
            endApplyTime,
            customerId: customer?.id,
        });
        setRecordList(resp?.data?.applications);
        setTotal(resp?.data?.totalSize);
        setLoading(false)
    }

    useEffect(() => {
        if (customer?.id) {
            fetchRecordList()
        }
    }, [customer]);

    const onTableChange = ({ current, pageSize }) => {
        paginationRef.current = {
            pageNumber: current,
            pageSize,
        };
        fetchRecordList();
    };

    const search = () => {
        paginationRef.current = { ...initialPaginantion };
        fetchRecordList();
    };

    const reset = () => {
        form.resetFields();
        search();
    };


    const goDetail = (record) => {
        history.push({
            pathname: `/app/application-record/${record.applyId}/detail`,
            state: {
                customer: record,
            },
        })
    }

    const onDateChange = () => {
        form.setFieldsValue({
            quickDay: null
        });
        search();
    }

    const onQuickDayChange = () => {
        form.setFieldsValue({
            date: null
        });
        search();
    }

    return <div>
        <Form
            form={form}
            initialValues={initialValues}
        >
            <Row gutter={12}>
                <Space>
                    <Form.Item name="date">
                        <DatePicker picker='quarter' onChange={onDateChange}></DatePicker>
                    </Form.Item>
                    <Form.Item
                        name="quickDay"
                    >
                        <Radio.Group defaultValue={7} buttonStyle="solid" onChange={onQuickDayChange}>
                            <Radio.Button value={7}>{lang.近7天}</Radio.Button>
                            <Radio.Button value={30}>{lang.近30天}</Radio.Button>
                        </Radio.Group>
                    </Form.Item>
                </Space>
            </Row>
            <Row gutter={12}>
                <Col span={6}>
                    <Form.Item
                        name='applyPersonUsername'
                        label={lang.申请账号}
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='applyPersonCustomerName'
                        label={lang.申请人所属客户}
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col span={6}>
                    <Form.Item
                        name='robotSn'
                        label={lang.机器SN}
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
                <Col span={6} >
                    <Form.Item
                        name='robotOwnerCustomerName'
                        label={lang.机器所属客户}
                    >
                        <Input></Input>
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={6}>
                    <Form.Item
                        name='applyStates'
                        label={lang.授权状态}
                    >
                        {
                            <Select mode="multiple" showArrow placeholder={lang.授权状态}>
                                {Object.entries(APPLY_STATUS).map((item) => (
                                    <Select.Option value={item[0]} key={item[0]}>
                                        {lang[item[1]]}
                                    </Select.Option>
                                ))}
                            </Select>
                        }
                    </Form.Item>
                </Col>
                <Col span={6}></Col>
                <Col span={6}></Col>
                <Col span={6} style={{ textAlign: 'right' }}>
                    <Space size="small" align='end'>
                        <Button
                            htmlType="submit"
                            type="primary"
                            ghost
                            onClick={search}
                        >
                            {lang.查询}
                        </Button>
                        <Button onClick={() => reset()}>{lang.重置}</Button>
                    </Space>
                </Col>
            </Row>
        </Form>

        <Table
            columns={columns}
            dataSource={recordList}
            loading={loading}
            onChange={onTableChange}
            pagination={{
                current: paginationRef.current.pageNumber,
                pageSize: paginationRef.current.pageSize,
                total
            }}
        >

        </Table>
    </div>
}

export default ApplicationList;