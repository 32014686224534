import HttpClient from "../../api/HttpClient";
import envAPI from "../../api/config";

import { apiIntercept } from "../../api";

const http = HttpClient.create(envAPI.cedarApiUrl);

// 启动拦截，添加token
apiIntercept(http);

interface IGetParams {
    page: number,
    pageSize: number,
    filter?: string
}

interface IGetFilterParams {
    page: number,
    pageSize: number,
    queryFilter?: string,
}

interface UserRolesParams{
    person_id: string
}

interface AttachRolesParams{
    person_id: string
}

interface DetachRolesParams{
    person_id: string
}

class AppUser{
    get(params: UserRolesParams){
        const { person_id }  = params;
        return http.get(`/cedar/v1alpha1/tenants/-/persons/${person_id}/roles`);
    }

    //当前用户所有角色 
    getAllRole(params: UserRolesParams){
        const { person_id }  = params;
        return http.get(`/cedar/v1alpha1/tenants/-/persons/${person_id}/roles?includeSystemRoles=true`);
    }

    getOne(name: string){
        return http.get(`/cedar/v1alpha1/${name}`);
    }

    getUserInfo(personId: string) {
        return http.post(`/cedar/v1/person/getById`, { personId })
    }

    getCutomerInfo(personId: string) {
        return http.get(`/cedar/v1alpha1/persons/${personId}/customer`)
    }

    attach(params: AttachRolesParams) {
        const { person_id } = params;
        return http.post(`/cedar/v1alpha1/tenants/-/persons/${person_id}:attachRoles`,params)
    }

    detach(params: DetachRolesParams) {
        const { person_id } = params;
        return http.post(`/cedar/v1alpha1/tenants/-/persons/${person_id}:detachRoles`,params)
    }
    getApply (
        params: IGetParams,
        tenantId: string
      ) {
        return http.get(`/cedar/v1alpha1/tenants/${tenantId}/roles`, params);
      }
    // 查询自身所属用户
    getOwnCustomersList(customerId: string, params: IGetFilterParams){
        return http.get(`/cedar/v1alpha1/customers/${customerId}/persons`, params)
    }
    // 查询高仙相关客户
    getListSubCustomerPersonsForGs(customerId: string, params: IGetFilterParams){
        return http.get(`/cedar/v1alpha1/customers/${customerId}/persons:gsSubCustomerPersons`, params)
    }
    // 查询独立租户相关客户
    getListSubCustomerPersonsForExternal(customerId: string, params: IGetFilterParams){
        return http.get(`/cedar/v1alpha1/external/customers/${customerId}/persons:subCustomerPersons`, params)
    }
}

class RobotPermission {
    // 获取某个用户绑定的全部运维大区
    getListPersonBindMaintenanceRegions(personId: string){
        return http.get(`/cedar/v1alpha1/persons/{personId}/bindings/maintenanceRegions`, { personId })
    }
    // 更新运维大区绑定关系
    submitPersonBindMaintenanceRegions(personId: string, data: {
        addRegionCodes: string[],
        removeRegionCodes: string
    }) {
        return http.post(`/cedar/v1alpha1/persons/${personId}/bindings/maintenanceRegions`, data)
    }
    // 获取用户绑定的终端项目
    getListPersonBindWorkspaces(personId: string){
        return http.get(`/cedar/v1alpha1/persons/${personId}/bindings/workspaces`)
    }
    // 获取全量的终端项目
    getAllWorkSpaces(customerId: string, parmas: any) {
        return http.get(`/cedar/v1alpha1/customers/${customerId}/workspaces`, parmas)
    }
    // 更新绑定关系
    submitPersonBindWorkspaces(personId: string, data: {
        addWorkspaceIds: string[],
        removeWorkspaceIds: string[],
    }){
        return http.post(`/cedar/v1alpha1/persons/${personId}/bindings/workspaces`, data)
    }
    // 获取客户已绑定的机器
    getListPersonDirectBindRobotSns(personId: string){
        return http.get(`/cedar/v1alpha1/persons/${personId}/bindings/directBindRobots`)
    }
    getListPersonDirectBindRobots(data: any) {
        return http.post(`/cedar/v1alpha1/person/directBind/robots`, data)
    }
    // 获取客户未绑定的机器人数据
    getListPersonUnbindRobots(data: any) {
        return http.post(`/cedar/v1alpha1/person/available/directBind/robots`, data)
    }
    // 获取全量机器人数据
    getListCustomerRobots(customerId: string, parmas: any){
        return http.get(`/cedar/v1alpha1/customers/${customerId}/robots:binding`, parmas)
    }
    // 更新机器人数据
    submitPersonDirectBindRobots(personId: string, data: any){
        return http.post(`/cedar/v1alpha1/persons/${personId}/bindings/directBindRobots`, data)
    }
}

const appUserApi = new AppUser();
const robotPermissionApi = new RobotPermission();

export {
    appUserApi,
    robotPermissionApi
}