export const APPLY_STATUS = {
    PENDING: '未申请',
    UNDER_REVIEW: '审批中',
    SYSTEM_APPROVED: '已通过A',
    MANUAL_APPROVED: '已通过B',
    AUTO_APPROVED: '已通过C',
    MANUAL_REJECTED: '未通过',
    SYSTEM_INTERCEPTED: '系统过滤',
};

export const RED_APPLY_STATUS = ['MANUAL_REJECTED', 'SYSTEM_INTERCEPTED'];
export const GREEN_APPLY_STATUS = ['SYSTEM_APPROVED', 'AUTO_APPROVED', 'MANUAL_APPROVED']