import HttpClient from "./HttpClient";
import envAPI from "./config";
import type { Person } from "./data";
import { apiIntercept, apiInterceptWithoutToken } from "./index";

const http = HttpClient.create(envAPI.cedarApiUrl);
const httpNoToken = HttpClient.create(envAPI.cedarApiUrl);

// 启动拦截，添加token
apiIntercept(http);
// 无token 拦截，包括登录的cookie设置
apiInterceptWithoutToken(httpNoToken);

interface CustomerParams {
  types?: 'SYSTEM' | 'BUSINESS',
  onlyGsManageable?: boolean
}

// 查询 公用接口
interface IGetParams {
  account: string | undefined,
  page: number,
  pageSize: number,
  showDeleted?: boolean,
  userGroup?: string,
  personId?: string,
  orderBy?: string
}
/**
 * @登录 
 */
// 登录参数
interface ISignInParams {
  username: string,
  password: string,
  userpool?: string,
  securityCode?: string,
  redirect?: string,
  mode?: string,
  ticket?: string,
  requestSource: string
}
// 设置新密码参数
interface ISetPasswordParams {
  password: string,
  confirmedPassword: string,
  userpool: string,
}
// 忘记密码， 发送邮件
interface IforgotPasswordParams {
  username: string,
  securityCode?: string
}
// 忘记密码， 重置密码
interface IResetPasswordParams {
  username: string,
  confirmationCode: string,
  password: string
}
// 隐私数据迁移参数
interface IMigrateParams {
  person: string | undefined,
  newPrivacyRegion: string,
  newPassword: string
}
// 获取手机验证码参数
interface IMbileCodeParams {
  phoneNumber: string,
  areaCode: string,
  locale: string
}
// 手机验证码登录参数
interface ISignInByPhoneParams {
  phoneNumber: string,
  areaCode: string,
  verificationCode: string,
  securityCode?: string,
}

class Login {
  // 登录
  signIn(params: ISignInParams) {
    const { redirect, mode, ticket, username, password, securityCode, requestSource } = params;
    const _params: {
      username: string,
      password: string,
      securityCode?: string | undefined;
      requestSource: string
    } = { username, password, requestSource }
    if (securityCode) _params['securityCode'] = securityCode;
    if (redirect) {
      if (mode && ticket) {
        return httpNoToken.post(`/gas/api/v1beta2/auth/signin?mode=${mode}&ticket=${ticket}&redirect=${encodeURIComponent(redirect)}`, _params);
      } else {
        return httpNoToken.post(`/gas/api/v1beta2/auth/signin?redirect=${encodeURIComponent(redirect)}`, _params);
      }
    } else {
      //?redirect=${encodeURIComponent(envAPI.homeUrl)}
      return httpNoToken.post(`/gas/api/v1beta2/auth/signin?redirect=${encodeURIComponent(envAPI.homeUrl)}`, _params);
    }
  }

  // 获取自己的资料
  getMe() {
    return http.get(`/cedar/v1alpha1/tenants/context`);
  }

  // 设置新密码
  setPassword(params: ISetPasswordParams) {
    const _params = {
      ...params,
      session: localStorage.getItem('view.login.session')
    }
    return http.post(`/gas/api/v1alpha1/auth/challenge/new-password-required`, _params)
  }
  // 获取安全码
  // options.width=180, options.height=40, options.len=5, options.chartype=1(1-6),options.fontsize=25
  getCode() {
    return httpNoToken.get(`/gas/api/v1alpha1/captcha?options.width=180&options.height=40&options.fontsize=30`);
  }

  // 刷新token
  async refreshToken() {
    const _reToken = localStorage.getItem('view.login.refreshToken');
    const params = { refreshToken: _reToken }
    if (_reToken) {
      const res = await httpNoToken.post(`/gas/api/v1alpha1/auth/token`, params);
      const { error, success } = res;
      if (success) return Promise.resolve(res)
      else return Promise.reject(error);
    }
  }

  // 根据ticket获取token
  getTokenByTicket(_ticket: string) {
    if (_ticket) return httpNoToken.get(`/gas/api/v1alpha1/sso/token?ticket=${_ticket}`)
    else return Promise.reject(false);
  }

  // 忘记密码: 发送邮件
  async forgetPassword(params: IforgotPasswordParams) {
    try {
      const res = await httpNoToken.post(`/gas/api/v1alpha1/auth/forgot-password`, params);
      const { error, success } = res;
      if (success) return Promise.resolve(res)
      else return Promise.reject(error);
    } catch (err: any) {
      const { data } = err;
      const { error } = data || {};
      return Promise.reject(error);
    }
    // return httpNoToken.post(`/gas/api/v1alpha1/auth/forgot-password`, params);
  }

  // 忘记密码: 发送邮件
  resetPassword(params: IResetPasswordParams) {
    return httpNoToken.post(`/gas/api/v1alpha1/auth/forgot-password/confirm`, params);
  }

  // 隐私数据迁移
  migratePrivacyRegion(params: IMigrateParams) {
    const { person } = params;
    const _params = params;
    delete _params.person
    return http.post(`/cedar/v1alpha1/${person}:migratePrivacyRegion`, _params);
  }

  // 获取手机验证码
  getMobileCode(params: IMbileCodeParams) {
    return httpNoToken.post(`/gas/api/v1alpha1/auth/mobile/code`, params);
  }

  // 手机号验证码登录
  async signInByPhone(params: ISignInByPhoneParams, redirect: string) {
    try {
      const res = await httpNoToken.post(`/gas/api/v1beta2/auth/mobile?redirect=${redirect}`, params);
      const { error, success } = res;
      if (success) return Promise.resolve(res)
      else return Promise.reject(error);
    } catch (err: any) {
      const { data } = err;
      const { error } = data || {};
      return Promise.reject(error);
    }
  }

  // 获取隐私信息 md
  async getUserAgreementInfo() {
    try {
      const res = await httpNoToken.get(`/gas/api/v1alpha1/user/agreement/versions/latest`);
      const { error, success } = res;
      if (success) return Promise.resolve(res.data)
      else return Promise.reject(error);
    } catch (err: any) {
      const { data } = err;
      const { error } = data || {};
      return Promise.reject(error);
    }
  }
  async getPrivacyAgreementInfo() {
    try {
      const res = await httpNoToken.get(`/gas/api/v1alpha1/user/privacy-agreement/versions/latest`);
      const { error, success } = res;
      if (success) return Promise.resolve(res.data)
      else return Promise.reject(error);
    } catch (err: any) {
      const { data } = err;
      const { error } = data || {};
      return Promise.reject(error);
    }
  }
}
const loginApi = new Login();
/**
 * 授权
*/
class GSAuthorize {
  // 获取授权的租户
  async getAuthorizeTenants(personId: string) {
    try {
      const res = await http.get(`/cedar/v1alpha1/persons/${personId}/tenants?view=FULL`);
      if (res) return Promise.resolve(res)
    } catch (err: any) {
      const { data } = err;
      const { error } = data || {};
      return Promise.reject(error);
    }
  }

  // 切换token
  async switchToken(params: {
    // token: string;
    tenantId: string;
    role: string
  }) {
    try {
      const res = await http.post(`/gas/api/v1alpha1/auth/switch/role-token`, params);
      const { error, success, data } = res;
      if (success) return Promise.resolve(data)
      else return Promise.reject(error);
    } catch (err: any) {
      const { data } = err;
      const { error } = data || {};
      return Promise.reject(error);
    }
  }
}

interface IGetPersonsParams {
  pageToken: string,
  pageSize: number,
  filter?: string
}
interface IEditPersonParams {
  tenantId: string | undefined,
  personId: string | undefined,
  displayName?: string,
  email?: string,
  phoneNumber?: string,
  gender?: string,
  profile?: string,
  password?: string,
  areaCode?: string,
  privacyRegion?: string,
  state?: string,
  familyName?: string,
  givenName?: string,
  locale?: string,
}
interface IRemovePersonParams {
  tenantId: string,
  personId: string
}
/**
 * @cedar 用户
 */
class Persons {
  get(
    tenant: string,
    params: IGetPersonsParams
  ) {
    return http.get(`/cedar/v1alpha1/tenants/${tenant}/persons`, params);
  }

  create(params: {
    tenantId: string;
    person: Person;
  }) {
    const { tenantId, person } = params;
    return http.post(`/cedar/v1alpha1/tenants/${tenantId}/persons`, person);
  }
  update(params: IEditPersonParams, updateMask: string) {
    const { tenantId, personId } = params;
    const _pm = {
      ...params
    }
    delete _pm.tenantId;
    delete _pm.personId;

    return http.patch(`/cedar/v1alpha1/tenants/${tenantId}/persons/${personId}?updateMask=${updateMask}`, _pm)
  }
  delete(params: IRemovePersonParams) {
    const { tenantId, personId } = params;
    return http.delete(`/cedar/v1alpha1/tenants/${tenantId}/persons/${personId}`);
  }

  // 管理员 直接创建 用户
  async createDirectly(params: {
    tenant: string;
    person: Person;
    presetRoles: string[],
    roleIds: string[]
  }) {
    try {
      const { tenant, person, presetRoles, roleIds } = params;
      const res = await http.post(`/cedar/v1alpha1/${tenant}/persons:adminCreate`, {
        person,
        presetRoles,
        roleIds
      });
      return Promise.resolve(res);
    } catch (err: any) {
      const { data } = err;
      return Promise.reject(data)
    }
  }

  // 完善用户信息
  async confirm(personId: string, params: {
    person: Person;
    emailBinding: {
      email: string;
      verificationCode: string;
    },
    phoneNumberBinding: {
      phoneNumber: string;
      areaCode: string;
      verificationCode: string;
    }
  }) {
    try {
      const res = await http.post(`/cedar/v1alpha1/tenants/-/persons/${personId}:confirm`, { ...params, enableInternalRuleCheck: true });
      return Promise.resolve(res);
    } catch (err: any) {
      const { data } = err;
      return Promise.reject(data)
    }
  }

  // 用户 应用的权限
  async getIAMPermissions({
    tenantId,
    personId
  }: {
    tenantId: string,
    personId: string
  }) {
    try {
      const res = await http.get(`/cedar/v1alpha1/tenants/${tenantId}/persons/${personId}/permissions?app=IAM`);
      return Promise.resolve(res);
    } catch (err: any) {
      const { data } = err;
      return Promise.reject(data)
    }
  }

  // 用户 各应用的权限
  async getPermissions({
    tenantId,
    personId,
    appType,
  }: {
    tenantId: string,
    personId: string,
    appType: string
  }) {
    try {
      const res = await http.get(`/cedar/v1alpha1/tenants/${tenantId}/persons/${personId}/permissions?app=${appType}`);
      return Promise.resolve(res);
    } catch (err: any) {
      const { data } = err;
      return Promise.reject(data)
    }
  }

  // 验证邮箱是否存在
  async emailExist({
    email
  }: {
    email: string
  }) {
    try {
      const res = await httpNoToken.get(`/gas/api/v1alpha1/email-binding/exists`, {
        email
      });
      return Promise.resolve(res?.data);
    } catch (err: any) {
      const { data } = err;
      return Promise.reject(data)
    }
  }

  // 验证手机号是否存在
  async mobileExist({
    phoneNumber,
    areaCode
  }: {
    phoneNumber: number,
    areaCode: number
  }) {
    try {
      const res = await httpNoToken.get(`/gas/api/v1alpha1/mobile-binding/exists`, {
        phoneNumber,
        areaCode
      });
      return Promise.resolve(res?.data);
    } catch (err: any) {
      const { data } = err;
      return Promise.reject(data)
    }
  }

  async associatedApplications({
    personId
  }: {
    personId: string
  }) {
    try {
      const res = await http.post(`/cedar/v1/person/associated/application/list`, {
        personId
      });
      return Promise.resolve(res?.data);
    } catch (err: any) {
      const { data } = err;
      return Promise.reject(data)
    }
  }  
}
const personApi = new Persons();

interface attachRoleParams {
  accountId: string,
  user: string,
  id: string,
  policies: string[]
}
interface IGetOneUser {
  account: string,
  user: string
}
interface IEditUserParams {
  accountId: string,
  personId: string,
  id: string,
  name: string,
  title: string,
  description: string,
  privacySub: string,
  policies: object[]
}
interface IIniteUserParams {
  tenant: string;
  inviteeEmail: string;
  locale: string;
  effectiveTimeHours: number;
  presetRoles: {
    predefinedRole: string
  }[]
}
/**
 * @iam 用户
 */
class Users {
  get(params: IGetParams) {
    const { account } = params;
    const _params = { ...params };
    delete _params.account;
    return http.get(`/iam/v1alpha1/accounts/${account}/users`, _params);
  }

  getOne(params: IGetOneUser) {
    const { account, user } = params;
    return http.get(`/iam/v1alpha1/accounts/${account}/${user}`);
  }

  // 更新
  update(params: IEditUserParams, updateMask: string) {
    const { accountId, id } = params;
    return http.patch(`/iam/v1alpha1/accounts/${accountId}/users/${id}?updateMask=${updateMask}`, params);
  }

  // 分配角色
  attachRole(params: attachRoleParams) {
    const { accountId, user, policies } = params;
    const _params = {
      // user,
      policies
    }
    return http.post(`/iam/v1alpha1/accounts/${accountId}/${user}:attachUserPolicy`, _params);
  }

  // 移除角色
  removeRole(params: attachRoleParams) {
    const { accountId, user, policies } = params;
    const _params = {
      user,
      policies
    }
    return http.post(`/iam/v1alpha1/accounts/${accountId}/${user}:removeUserPolicy`, _params);
  }

  // 删除
  delete(userId: string, account: string) {
    return http.delete(`/iam/v1alpha1/accounts/${account}/users/${userId}`);
  }

  // 邀请用户
  invite(params: IIniteUserParams) {
    const { tenant } = params;
    return http.post(`/cedar/v1alpha1/${tenant}:inviteUser`, params)
  }

}

/**
 * @角色 account租户某个应用的角色 操作
 */
class Roles {
  // 查询一组：用户自己创建的角色 @可以修改
  get(params: IGetParams, service: string) {
    const { account } = params;
    const _params = {
      ...params,
      filter: `service=${service}`
    }
    delete _params.account;
    return http.get(`/iam/v1alpha1/accounts/${account}/policies`, _params);
  }

  // 获取注册可用的系统角色
  getCanBeRegistered(params: {
    page: number,
    pageSize: number,
  }) {
    return http.get(`/iam/v1alpha1/accounts/gs/roles`, {
      filter: 'canBeRegistered',
      ...params
    });
  }

  // 获取业务角色
  getBusinessRoles(params: {
    page: number,
    pageSize: number,
  }, tenantId: string) {
    return http.get(`/cedar/v1alpha1/tenants/${tenantId}/roles`, params);
  }

  // 查询一组：内置角色 @不允许修改
  getFix(params: IGetParams, service: string) {
    const _params = {
      ...params,
      filter: `service=${service}`
    }
    delete _params.account;
    return http.get(`/iam/v1alpha1/accounts/gs/policies`, _params);
  }

  // 查询 一条
  getOne(pid: string, account: string) {
    // '820e24f0-a954-47db-bad1-2382663e2388'
    return http.get(`/iam/v1alpha1/accounts/${account}/policies/${pid}`);
  }

  // 获取所有业务角色
  getAllRoles(id: string, params: CustomerParams) {
    return http.get(`/cedar/v1alpha1/customers/${id}/roles`, params);
  }

  // 获取manageble 列表
  getManagableRoles(id: string, params: CustomerParams) {
    return http.get(`/cedar/v1alpha1/customers/${id}/roles/manageable`, params);
  }
}
const rolesApi = new Roles();

/**
 * @机器人
 */
interface IGetRobotsParams {
  page: number,
  pageSize: number
}
class Robots {
  get(params: IGetRobotsParams) {
    return http.get(`/bot/v1alpha1/robots`, params);
  }
}

/**
 * @租户
 * 
**/
class Tenants {
  getOne(
    tenant: string
  ) {
    return http.get(`/cedar/v1alpha1/tenants/${tenant}`)
  }
}
const tenantApi = new Tenants();

// 运维大区
class CommonApi {
  async getRegions() {
    const params = {
      page: 1,
      pageSize: 2000
    }
    return http.get(`/bot/v1alpha1/maintenanceRegions`, params);
  }

  // 获取国家
  async getCountries(params: {
    noPaging?: boolean,
    lang: string
  }) {
    return http.get(`/coma/v1alpha1/countries`, params);
  }

  // 获取地区
  async getStates(params: {
    noPaging?: boolean,
    lang: string,
    countries: string
  }) {
    const { countries, ...res } = params;
    return http.get(`/coma/v1alpha1/countries/${countries}/states`, res);
  }
}
const commonApi = new CommonApi();

// 运维大区
class Regions {
  async get() {
    const params = {
      page: 1,
      pageSize: 2000
    }
    return http.get(`/bot/v1alpha1/maintenanceRegions`, params);
  }
  // 全量
  async getV3() {
    const params = {
      version: 3
    }
    return http.get(`/cedar/v1alpha1/refs/maintenanceRegions`, params);
  }
  // 全量包含历史废弃数据
  async getV2() {
    const params = {
      version: 2
    }
    return http.get(`/cedar/v1alpha1/refs/maintenanceRegions`, params);
  }
}
const regionApi = new Regions();

export {
  http,
  httpNoToken,
  Login,
  loginApi,
  Users,
  Persons,
  personApi,
  Roles,
  rolesApi,
  Robots,
  GSAuthorize,
  tenantApi,
  commonApi,
  regionApi
}