/**
 * conditionalBind和directBind的父级为robotBindRefManage
 */
export class RolePermission {
  userAdd: boolean; // 添加用户
  editUser: boolean; // 编辑用户
  resetPassword: boolean; // 设置新密码
  editRole: boolean; // 编辑角色
  robotBindRefManage: boolean;
  conditionalBind: boolean;
  directBind: boolean;
  constructor(rolePermission: RolePermission) {
    this.userAdd = rolePermission.userAdd;
    this.editUser = rolePermission.editUser;
    this.resetPassword = rolePermission.resetPassword;
    this.editRole = rolePermission.editRole;
    this.robotBindRefManage = rolePermission.robotBindRefManage;
    this.conditionalBind = rolePermission.conditionalBind;
    this.directBind = rolePermission.directBind;
  }
}

/**
 * 单独用于校验角色相关的权限
 * 七个权限点
 * 1、绑定机器
 * 1.1、按条件配置
 * 1.2、直接选择机器人
 * 2、添加用户
 * 3、编辑
 * 4、设置新密码
 * 5、编辑角色
 * 三种角色前端写死权限点：
 * 1、超级管理员 SUPER_ADMINISTRATOR 1-5权限点
 * 2、租户超管 TENANT_SUPERVISOR 1-5
 * 3、用户管理员 TENANT_USER_MANAGER 2-5
 * 4、高仙FAE使用权限点 1/1.2
 */
class Admin {
  permission: RolePermission;

  constructor(rolePermission: RolePermission) {
    this.permission = new RolePermission(rolePermission);
  }
}

export class SuperAdmin extends Admin {
  constructor() {
    super({
      userAdd: true,
      editUser: true,
      resetPassword: true,
      editRole: true,
      robotBindRefManage: true,
      conditionalBind: true,
      directBind: true,
    });
  }
}

export class TenantSupervisor extends Admin {
  constructor() {
    super({
      userAdd: true,
      editUser: true,
      resetPassword: true,
      editRole: true,
      robotBindRefManage: true,
      conditionalBind: true,
      directBind: true,
    });
  }
}

export class TenantUserManager extends Admin {
  constructor() {
    super({
      userAdd: true,
      editUser: true,
      resetPassword: true,
      editRole: true,
      robotBindRefManage: false,
      conditionalBind: false,
      directBind: false,
    });
  }
}
