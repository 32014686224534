import { http } from "src/api/HttpClient";

class MachineAuthorization {
  // 查询客户待审批申请总数
  async underReviewCount(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/customer/robotAuthApply/underReviewCount`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 查询无授权机器总数
  async unauthorizedCount(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/person/bindings/maintenanceRegions/robots/unauthorizedCount`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 运维大区下机器列表（含授权状态）
  async robotAuthInfo(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/person/bindings/maintenanceRegions/robots/authInfos`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 运维大区绑定前，查询需要发送的授权申请总数
  async maintenanceRegionsPendingCount(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/person/bindings/maintenanceRegions/robots/pendingCount`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 给一个人绑定/解绑运维大区列表，并发出授权申请
  async sendMaintenanceRegionsAuthApply(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/person/binding/maintenanceRegions/sendAuthApply`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 直接绑定页面，查询未发出授权申请的机器总数
  async directBindPendingCount(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/person/directBind/robots/pendingCount`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }


  // 用户绑定/解绑 （直接绑定）机器人列表,并发出授权申请
  async sendDirectbindAuthApply(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/person/directBind/robots/sendAuthApply`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 根据sn查询机器所属运维大区是否中国区
  async isChinaRegion(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/robot/isChinaRegion`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 用户直接绑定页面,发出单个授权申请
  async sendSingleAuthApply(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/person/robot/sendAuthApply`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 客户审批中心页面，分页查询不同状态的审批列表
  async robotAuthApplys(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/customer/robots/authApplys`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 批量审批授权申请
  async batchApproveAuthApply(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/customer/batch/approve/authApply`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 申请记录（查询本客户下用户发出的申请记录）
  async recordApplys(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/customer/persons/robots/authApplys`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 单个获取授权申请详情
  async singleAuthApplyDetail(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/authApply`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 分页查询授权申请变更记录
  async authApplyLogs(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/authApplyLogs`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 查询SN是否存在
  async isSnExist(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/robot/isSnExist`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }


  // 校验snList所属客户是否有任意一个开启了人工授权审批
  async snManualApprove(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/robots/manualApprove/customers/exist`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

  // 用户绑定机器人校验（web端）
  async checkSnPermission(
    params
  ) {
    try {
      const res = await http.post(`/cedar/v1/web/person/bind/robot/checkPermission`, params);
      if (res) {
        return Promise.resolve(res);
      }
    } catch (error) {
      const data = error;
      return Promise.reject(data);
    }
  }

}

const machineAuthorizationApi = new MachineAuthorization();

export {
  machineAuthorizationApi
}