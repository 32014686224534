import React, { useEffect, useState, useContext } from "react";
import { Menu } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import { GlobalContext } from "src/view/reducer";
import { getLocale } from "src/libs/utils";
import { switchLocale} from 'src/i18n/index';

const { SubMenu } = Menu;
/**
 * 语言选择：用于顶部菜单
*/
function LangMenu() {
  const { userLocale, dispath } = useContext(GlobalContext);
  const locale = getLocale();
  const _locale = $storage.get('locale');
  const [langTitle, setLangTitle] = useState(null);
  const [localeKey, setLocaleKey] = useState(_locale);

  const updateMenuTitle = _locale => {
    let txt = '';
    switch (_locale) {
      case 'en-US':
      case 'en':
        txt = 'English';
        break;

      case 'zh-CN':
      case 'cn':
        txt = '简体中文';
        break;

      case 'ja-JP':
        txt = '日本語';
        break;

      // case 'ko':
      //   txt = '한국어';
      //   break;

      // case 'pl':
      //   txt = 'Polski';
      //   break;

      default:
        txt = 'English'
        break;
    }
    setLangTitle(txt);
  }

  // 切换语言
  const handleMenuClick = item => {
    const _locale = item.key;
    // 是否切换语言 依赖 localeKey 是否set
    setLocaleKey(_locale);
  }
  
  // 切换时需要处理的逻辑
  const todo = _locale_ => {
    // 更新缓存
    $storage.set('locale', _locale_);
    // 更新title
    updateMenuTitle(_locale_);
    // 获取语言包
    switchLocale(_locale_);
  }

  useEffect(() => {
    // 切换的语言发生变化 才reload
    if (localeKey !== _locale) todo(localeKey);
  }, [localeKey])

  useEffect(() => {
    updateMenuTitle(_locale);
  }, []);

  return (
    <>
      <SubMenu
        key="sub_lang"
        title={langTitle}
        onClick={handleMenuClick}
        icon={<GlobalOutlined />}
      >
        <Menu.Item key="zh-CN">简体中文</Menu.Item>
        <Menu.Item key="en">English</Menu.Item>
        <Menu.Item key="ja-JP">日本語</Menu.Item>
        {/* <Menu.Item key="ko">한국어</Menu.Item>
        <Menu.Item key="po">Polski</Menu.Item> */}
      </SubMenu>
    </>
  )
}

export default LangMenu;