import React from "react";
import { Avatar, Image } from 'antd';
// import avatar14 from "../../assets/images/avatar14";
// const bot = require('../../assets/images/avatar14.svg');
// https://avatars.dicebear.com/api/avataaars/gl.svg?b=%23f7f7f7&top[]=shortHair&top[]=bob&hairColor[]=black&accessoriesColor[]=black&clothes[]=sweater&clothesColor[]=blue01&skin[]=light
// const src = `https://avatars.dicebear.com/api/avataaars/a.svg?b=%23f7f7f7&top[]=shortHair&top[]=bob&hairColor[]=black&accessoriesColor[]=black&clothes[]=collarAndSweater&clothesColor[]=blue01&eyes[]=default&eyebrow[]=default&mouth[]=default&skin[]=light`;

const IconGasUser = props => (
  <Image
    src={`https://avatars.dicebear.com/api/avataaars/${props.user.gender?.toLowerCase() === 'male' ? 'female' : 'male'}/${props.user.id}.svg`}
    preview={false}/>
);

export default IconGasUser;