import React from "react";
import { Image } from 'antd';
import Icon from '@ant-design/icons';
import customer from "assets/images/customer.svg";
import building from "assets/images/building.svg";
import node from "assets/images/node.svg";

// build
const IconBuildingColor = () => <Image src={customer} preview={false}/>;

// 节点
const IconNode= () => <Image src={node} preview={false}/>;

const Building = () => <Image src={building} preview={false}/>;

const SvgCustomer = () => (
  <svg fill="currentColor" viewBox="0 0 1024 1024" width="1em" height="1em">
    <path d="M989.725021 945.231538h-34.115003V393.842308c0-86.647308-61.421003-157.536923-136.480008-157.536923H614.429996v-78.77846C614.429996 70.889616 553.008993 0 477.949988 0H205.009969c-75.059005 0-136.480008 70.889616-136.480008 157.536923v787.694615H34.414959C15.307758 945.231538 0.289958 962.559 0.289958 984.615769c0 22.056769 15.0178 39.384231 34.125001 39.384231h955.310062c19.107201 0 34.125001-17.327462 34.125001-39.384231 0-22.056769-15.0178-39.384231-34.125001-39.384231z m-443.535029-708.916155v708.916155H136.769965V157.536923c0-43.323654 30.715501-78.768462 68.240004-78.768461h272.940019c37.534502 0 68.240004 35.444808 68.240004 78.768461v78.77846z m341.190021 708.916155H614.429996V315.073847h204.710013c37.534502 0 68.240004 35.444808 68.240004 78.768461v551.38923zM443.834985 251.643138H239.124972c-19.107201 0-34.115003 15.007802-34.115003 34.115003s15.007802 34.125001 34.115003 34.125001H443.834985c19.107201 0 34.115003-15.0178 34.115003-34.125001 0-19.107201-15.007802-34.115003-34.115003-34.115003z m0 204.710013H239.124972c-19.107201 0-34.115003 15.0178-34.115003 34.125002 0 19.107201 15.007802 34.115003 34.115003 34.115002H443.834985c19.107201 0 34.115003-15.007802 34.115003-34.115002s-15.007802-34.125001-34.115003-34.125002z m0 204.710014H239.124972c-19.107201 0-34.115003 15.007802-34.115003 34.115002s15.007802 34.125001 34.115003 34.125001H443.834985c19.107201 0 34.115003-15.0178 34.115003-34.125001 0-19.097203-15.007802-34.115003-34.115003-34.115002z m341.190021-204.710014h-68.240004c-19.107201 0-34.125001 15.0178-34.125001 34.125002 0 19.107201 15.0178 34.115003 34.125001 34.115002h68.240004c19.107201 0 34.115003-15.007802 34.115003-34.115002s-15.007802-34.125001-34.115003-34.125002z m0 204.710014h-68.240004c-19.107201 0-34.125001 15.007802-34.125001 34.115002s15.0178 34.125001 34.125001 34.125001h68.240004c19.107201 0 34.115003-15.0178 34.115003-34.125001 0-19.097203-15.007802-34.115003-34.115003-34.115002z" p-id="2223"></path>
  </svg>
);

 // 黑色 建筑icon
const IconBuilding = props => <Icon component={Building} {...props}/>;
// 菜单用icon
const IconCustomer = props => <Icon component={SvgCustomer} {...props}/>;

const Org = props => <Icon component={Building} {...props}/>;


export {
  IconBuildingColor,
  IconBuilding,
  IconCustomer,
  IconNode,
};