import envAPI from "src/api/config";
import { Roles, Tenant, BusinessRoles, IPermission } from 'src/api/data';
import { RolePermission, SuperAdmin, TenantSupervisor, TenantUserManager } from "./userPermission";

class CheckRole {
  // code: 是否设备管理员
  isCodeTanentAdmin(code: string) {
    if (code === 'TENANT_SUPERVISOR') return true
    else return false
  }

  // code: 是否超管
  isCodeSuperAdmin(code: string) {
    if (code === 'SUPER_ADMINISTRATOR') return true
    else return false
  }

  // code: 是否用户管理员
  isCodeUserManager(code: string) {
    if (code === 'TENANT_USER_MANAGER') return true
    else return false
  }

  // 是否gs租户
  isGSTenant(currentTenantId: string) {
    if (currentTenantId === envAPI.gsKey) return true
    else return false
  }

  hasCode(roles: Roles[], code: string) {
    if (roles && roles.length > 0) {
      const _has = roles.filter((role: Roles) => role.code === code);
      if (_has && _has.length > 0) return true;
      else return false;
    } else {
      return false;
    }
  }

  // 是否租户管理员 返回 true || false
  isTanentAdmin(roles: Roles[]) {
    return this.hasCode(roles, 'TENANT_SUPERVISOR');
  }

  // 是否用户管理员 返回 true || false
  isUserManager(roles: Roles[]) {
    return this.hasCode(roles, 'TENANT_USER_MANAGER');
  }

  // 是否设备管理员 返回 true || false
  isDeviceManager(roles: Roles[]) {
    return this.hasCode(roles, 'TENANT_ADMINISTRATOR');
  }

  // 是否超级管理员 返回 true || false
  isSuperAdmin(roles: Roles[]) {
    return this.hasCode(roles, 'SUPER_ADMINISTRATOR');
  }
}
export const checkRole = new CheckRole();

/**
 * @已有的权限
 * @来自permissions接口
 * 可以获取到所有 可显示的key: ['accessControl', 'accessControl.roleManage', 'accessControl.userManage']
**/
const genPerssions = (perssions: IPermission[], arr: string[], parentModule: string) => {
  if (!perssions) return;

  perssions.map((perssion: any) => {
    const { module, authority, children } = perssion;
    if (authority && authority.length > 0) {

      authority.forEach((auth: any) => {
        const { features, effect } = auth;
        if (effect === 'ALLOW') {
          features.forEach((f: any) => {
            let key = `${module}.${f}`;
            if (f === 'view') {
              key = module;
            }

            // 如有有父级key， 使用 . 拼接
            if (parentModule) {
              key = `${parentModule}.${key}`;
            }

            arr.push(key);
          })
        }
      })

    }

    // 页面内的功能使用page.feature.feature的格式: accessControl.roleManage
    if (children && Object.keys(children).length > 0) {
      const permArr = [];
      for (const key in children) {
        if (Object.prototype.hasOwnProperty.call(children, key)) {
          const perm = children[key];
          permArr.push(perm);
        }
      }
      const parentKey = parentModule ? `${parentModule}.${module}` : module;
      genPerssions(permArr, arr, parentKey)
    }
  })

}

/**
 * 超级管理员 租户超管 和用户管理员 hardcode 来管理七个权限
 * 其余角色通过读取权限树来获取权限
 * 两者之间是或的关系
 * @param state 
 * @param permission 
 */
const superAdminPermission = (new SuperAdmin()).permission;
const tenantSupervisorPermission = (new TenantSupervisor()).permission;
const tenantUserManagerPermission = (new TenantUserManager()).permission;

export function addPermission(state:any, permission: RolePermission) {
  state.canUserAdd = permission.userAdd || state.canUserAdd;
  state.canConditionBind = permission.conditionalBind || state.canConditionBind;
  state.canDirectBind = permission.directBind || state.canDirectBind;
  state.canEditUser = permission.editUser || state.canEditUser;
  state.canEditRole = permission.editRole || state.canEditRole;
  state.canResetPassword = permission.resetPassword || state.canResetPassword;
  state.canRobotBindRefManage = permission.robotBindRefManage || state.canRobotBindRefManage
}

interface IProps {
  roles?: Roles[];
  tenant?: Tenant;
  businessRoles?: BusinessRoles[];
  permissions: any
}

/**
 * useUpdateMe 中会调用此方法，将access更新到context
*/
export const access = (props: IProps) => {
  const { roles, tenant, businessRoles, permissions } = props;
  // console.log(businessRoles)
  const roleCodes = roles?.map(r => r.code) || [];

  const permKeys: string[] = [];
  if (permissions) {
    genPerssions(permissions, permKeys, '');
    // console.log('permissions', permKeys)
  }

  // 超管角色暂时没有 permissions 数据；permKeys.length === 0 && 
  if (roleCodes.includes('SUPER_ADMINISTRATOR')) {
    // 一些手动配置，尽量避免
  }

  // state init: 客户管理
  // key: permKeys.includes() - 代表已接入permissions接口配置，需要从接口拿权限
  // key: true | false - 代表还未接入权限控制, false - 可能下文的逻辑变为了true
  let state = {
    // 是否高仙用户
    isGSUser: false,
    isSuperAdmin: false,
    isFAESupervisor: false,
    // 组织维护员
    isOrganizationMaintainer: false,
    // 租户管理员
    isTenantAdmin: false,
    // 访问管控
    canIAM: false,

    /* -----应用----- */
    // IAM后台管理
    canConsole: false,
    // 报表
    canBI: true,
    // 物联网平台
    canIOT: false,
    // 机器人平台
    canCloud: true,
    // 开发者平台
    canOpenAPI: false,
    // 客户报表 数据中心
    canBiCenter: false,
    // 机器交付 华住注册
    canHwordGroup: false,

    /* -----客户管理----- */
    // 客户中心
    canCustomer: permKeys.includes('customerCenter'),
    // 客户查询
    canCustomerSearch: permKeys.includes('customerCenter.customerSearch'),
    // 添加客户
    canCustomerAdd: permKeys.includes('customerCenter.customerAdd'),
    // 添加客户
    canLogManage: permKeys.includes('customerCenter.logManage'),
    // 客户编辑
    canCustomerEdit: true,
    // 客户启用禁用
    canCustomerDisable: true,
    // 客户的用户管理
    canCustomerUserManage: permKeys.includes('customerCenter.userManage'),
    // 客户机器人管理
    canCustomerRobotManage: permKeys.includes('customerCenter.robotManage'),
    // 客户的内部组织管理
    canCustomerInnerOrgManage: permKeys.includes('customerCenter.organizationManage'),
    // 客户的终端项目管理
    canCustomerWkspaceManage: permKeys.includes('customerCenter.workspaceManage'),
    // 客户下的 添加终端项目
    canCustomerWkspaceAdd: permKeys.includes('customerCenter.workspaceManage.workspaceAdd'),
    canCustomerWkspaceEdit: permKeys.includes('customerCenter.workspaceManage.workspaceEdit'),
    // 客户下的 终端项目下的 机器管理
    canCustomerWkspaceRobotManage: permKeys.includes('customerCenter.workspaceManage.robotManage'),

    /* -----组织管理----- */
    // 组织管理
    canOrg: permKeys.includes('organizationManage'),
    // 组织查询
    canOrgSearch: permKeys.includes('organizationManage.organizationSearch'),
    // 添加组织
    canOrgAdd: permKeys.includes('organizationManage.organizationAdd'),
    // 编辑组织
    canOrgEdit: true,
    // 移动组织
    canMoveOrgs: permKeys.includes('organizationManage.organizationMove'),
    // 关系调整
    canOrgManageRelation: permKeys.includes('organizationManage.maintainerManage'),
    // 添加下一级组织节点（普通）
    canOrgAddChildren: permKeys.includes('organizationManage.organizationAdd'),
    // 组织启用禁用
    canOrgDisable: true,
    // 关系调整: 运维人员
    canOrgMaintainers: permKeys.includes('organizationManage.maintainerManage'),
    // 关系调整-运维人员: 添加
    canOrgMaintainersAdd: permKeys.includes('organizationManage.maintainerAdd'),
    // 关系调整-运维人员: 移动
    canOrgMaintainersMove: true,
    // 关系调整-运维人员: 移除
    canOrgMaintainersRemove: permKeys.includes('organizationManage.maintainerRemove'),

    // 设备运营
    canDeviceOperate: true,

    // 租户的终端项目
    canTenantWorkspace: permKeys.includes('workspaceManage'),
    canTtWkspaceAdd: permKeys.includes('workspaceManage.workspaceAdd'),
    canTtWkspaceEdit: permKeys.includes('workspaceManage.workspaceEdit'),
    canTtWkspaceSearch: permKeys.includes('workspaceManage.workspaceSearch'),
    canTtWkspaceRobotManage: permKeys.includes('workspaceManage.robotManage'),
    
    // 机器清单
    canInventory: permKeys.includes('robotList'),
    // 集团视图
    canCompanyGroup: permKeys.includes('groupCustomerView'),

    // 角色
    canUserManage: permKeys.includes("accessControl.userManage"),
    canRobotBindRefManage: permKeys.includes("accessControl.userManage.robotBindRefManage"),
    canConditionBind: permKeys.includes("accessControl.userManage.conditionalBind"), // 按条件绑定
    canDirectBind: permKeys.includes("accessControl.userManage.directBind"), // 直接绑定
    canUserAdd: permKeys.includes("accessControl.userManage.userAdd"), 
    canEditRole: permKeys.includes("accessControl.userManage.editRole"),
    canResetPassword: permKeys.includes("accessControl.userManage.resetPassword"),
    canEditUser: permKeys.includes("accessControl.userManage.editUser"),

    canApplyRecords: permKeys.includes("authApplyControl.applyRecords"),
    canApprovalCenter: permKeys.includes("authApplyControl.approvalCenter"),
  }

  if (tenant && tenant.id === envAPI.gsKey) {
    state.isGSUser = true;
  }

  roles && roles.forEach(role => {
    const { code } = role;

    switch (code) {

      // 超管：IAM后台管理，访问管控，客户管理
      case 'SUPER_ADMINISTRATOR':
        {
          // 超管 所有权限。
          state = new Proxy(state, {
            get: () => true
          });
        }
        break;

      // 租户管理员: 访问管控，客户管理
      case 'TENANT_SUPERVISOR':
        {
          state.canIAM = true;
          // 数据中心管理，是高仙内部用应用，不希望其他租户的管理员可见
          // state.canBiCenter = true;
           // 11-21  modified by linjunwei
           addPermission(state, tenantSupervisorPermission)
        }
        break;

      // 用户管理员：访问管控
      case 'TENANT_USER_MANAGER':
        {
          state.canIAM = true;
          addPermission(state, tenantUserManagerPermission)
        }
        break;

      // 开发者
      case 'DEVELOPER':
        {
          if (state.isGSUser) {
            state.canIOT = true;
            state.canOpenAPI = true;
          }
        }
        break;

      default:
        break;
    }
  });

  if (tenant && tenant.id === envAPI.gsKey) {
    state.isGSUser = true;
  }

  businessRoles && businessRoles.map(({ id, alias }) => {
    switch (alias) {
      // FAE主管
      case 'FAESupervisor':
        state['isFAESupervisor'] = true;
        break;

      // 大数据管理员
      case 'BigDataSupervisor':
        state.canBiCenter = true;
        break;

      // 租户维护员
      case 'OrganizationMaintainer':
        state.isOrganizationMaintainer = true;
        break;
      
      // 机器交付 华住注册
      case 'RobotDelivery':
        state.canHwordGroup = true;
        break;

      default:
        break;
    }
  })

  return state;
}
