import { trim } from "lodash";
import { APPLY_STATUS, GREEN_APPLY_STATUS, RED_APPLY_STATUS } from "src/constants";

export const normalizeWhitespace = (value) => {
  // 去除两端的空格
  return trim(value);
};

// 只保证所有项相同长度相同，不保证完全一致
export const arrayHasSameItem = (arr1, arr2) => {
  if (arr1.length !== arr2.length) {
    return false;
  }
  for (let i = 0; i < arr1.length; i++) {
    if (!arr2.includes(arr1[i])) {
      return false;
    }
  }
  return true;
};

export const renderColor = (applyState) => {
  let color = '';
  if (GREEN_APPLY_STATUS.includes(applyState)) {
    color = 'rgb(10, 183, 50)';
  } else if (RED_APPLY_STATUS.includes(applyState)) {
    color = 'rgb(245, 63, 63)'
  } else {
    color = 'silver'
  }

  return color
}