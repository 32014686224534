// 异步加载组件
import { asyncComponent } from 'react-async-component';

const LoginHome = asyncComponent({resolve: () => import("./Home")});
const ForgetPsw = asyncComponent({resolve: () => import("./ForgetPsw")});
const SetPsw = asyncComponent({resolve: () => import("./SetPsw")});
const ResetPsw = asyncComponent({resolve: () => import("./ResetPsw")});
const UserPrivacy = asyncComponent({resolve: () => import("./UserPrivacy")});
const Move = asyncComponent({resolve: () => import("./Move")});
const Activation = asyncComponent({resolve: () => import("./activation/Home")});
const Complete = asyncComponent({resolve: () => import("./complete/Home")});

const routers = [
  {
    path: '/login',
    component: LoginHome
  },
  {
    path: '/forget-password', // 忘记密码，发送邮件
    component: ForgetPsw
  },
  {
    path: '/set/psw', // 新用户首次登录，强制修改密码
    component: SetPsw
  },
  {
    path: '/reset-password',  // 用户忘记密码，重置密码
    component: ResetPsw
  },
  {
    path: '/user-privacy',  // 用户隐私协议
    component: UserPrivacy
  },
  {
    path: '/move',  // 隐私数据迁移
    component: Move
  },
  {
    path: '/sign-up', // 帐户激活
    component: Activation
  },
  {
    path: '/complete', // 完善信息
    component: Complete
  },
  {
    path: '/',
    component: LoginHome,
    exact: true
  },

  // {
  //   path: '/',
  //   component: <Main><APPHome/></Main>,
  //   exact: true
  // }
];

export default routers;