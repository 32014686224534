// import lang from "./language";
// import { i18n as i } from "../i18n/index";
import envAPI, { PROXY_ENV, regionValue, REGION } from "../api/config";
import { getLocale, transLocaleForCloud } from "./utils";
import moment from "moment";
import cleanImage from "../../assets/images/cleanLibrary.png"
import hwordGroupImage from "../../assets/images/hwordGroup.svg"

import factoryManagement from "../../assets/images/factoryManagement.svg"
import beep from "../../assets/images/beep.svg";
import reportCentre from "../../assets/images/reportCentre.svg"
import operationMaintenance from "../../assets/images/operationMaintenance.svg"
import parameterFlowManagement from "../../assets/images/parameterFlowManagement.svg"

/**
 * @url 跳转携带locale到cloud
 * ?locale=zh-CN，ko，pl，ja-JP，en-US
 **/
const cloud_locale = transLocaleForCloud(getLocale());

/**
 * copyright
 * 2019-2020
 **/
const LAST_YEAR = moment().subtract(1, "years").year();
const CURRENT_YEAR = moment().year();
const COPYRIGHT = `Copyright © ${CURRENT_YEAR}`;

/**
 * @应用配置
 * console IAM后台管理
 * gsCloudUrl 机器人平台
 * robotbi quickbi数据中心
 * biManage 数据中心管理
 * iot 物联网平台
 * openAPI 开发者中心
 **/
const APP_URL_CONFIG = {
  production: {
    name: "正式环境",
    homeUrl: `https://home${regionValue}.gs-robot.com`,
    serviceUrl: `https://service${regionValue}.gs-robot.com`,
    gsCloudUrl: REGION ? `https://service${regionValue}.gs-robot.com` : `https://cloud.gs-robot.com`,
    openAPI: `https://service${regionValue}.gs-robot.com/developer`,
  },
  release: {
    name: "预发布环境",
    homeUrl: 'https://home.release.gs-robot.com',
    serviceUrl: 'https://service.release.gs-robot.com',
    gsCloudUrl: "https://cloud.release.gs-robot.com",
    openAPI:
      "https://client.release.gs-robot.com/developerCenter/appManagement",
  },
  staging: {
    name: "测试环境",
    homeUrl: 'https://home.staging.gs-robot.com',
    serviceUrl: 'https://service.staging.gs-robot.com',
    gsCloudUrl: "https://cloud.staging.gs-robot.com",
    openAPI:
      "https://client.staging.gs-robot.com/developerCenter/appManagement",
  },
  test: {
    name: "测试环境",
    homeUrl: 'https://home.test.gs-robot.com',
    serviceUrl: 'https://service.test.gs-robot.com',
    gsCloudUrl: "https://service.test.gs-robot.com",
    openAPI:
      'https://service.test.gs-robot.com/developer',
  },
  dev: {
    name: "开发环境",
    homeUrl: 'https://home.dev.gs-robot.com',
    serviceUrl: 'https://service.dev.gs-robot.com',
    gsCloudUrl: "https://cloud.dev.gs-robot.com",
    iot: "https://home.dev.gs-robot.com/iot/dashboard",
    openAPI: "https://client.dev.gs-robot.com/developerCenter/appManagement",
  },
  local: {
    name: "本地环境",
    homeUrl: `https://home.${PROXY_ENV}.gs-robot.com`,
    serviceUrl: `https://service.${PROXY_ENV}.gs-robot.com`,
    gsCloudUrl: `https://cloud.${PROXY_ENV}.gs-robot.com`,
    openAPI: `https://client.${PROXY_ENV}.gs-robot.com/developerCenter/appManagement`,
  },
};
const APP_BASE_URLS = APP_URL_CONFIG[PROXY_ENV];
const SERVICES = {
  // 权限系统【超级管理员】
  console: {
    key: "console",
    param: "console",
    title: "IAM后台管理",
    image: `${envAPI.assets}/app-console.svg`,
    menuTitle: "IAM后台管理",
    description: "租户管理",
    descLocaleKey: "console描述",
    href: `${APP_BASE_URLS.homeUrl}/tenant-center#/gas/tenant/manage`,
  },
  // 权限系统【租户管理员】
  iam: {
    key: "iam",
    param: "iam",
    title: "访问管控",
    image: `${envAPI.assets}/app-iam.svg`,
    menuTitle: "访问管控",
    description: "管控各应用的权限分配",
    descLocaleKey: "iam描述",
    href: `${APP_BASE_URLS.serviceUrl}/iam?locale=${cloud_locale}`,
  },
  // 云平台
  gsCloud: {
    key: "gsCloud",
    param: "gsCloud",
    title: "机器人平台",
    image: `${envAPI.assets}/app-task.svg`,
    menuTitle: "机器人平台",
    description: "机器人业务管理",
    descLocaleKey: "cloud描述",
    href: `${APP_BASE_URLS.gsCloudUrl}?locale=${cloud_locale}/#/`,
  },
  // 报表系统
  report: {
    key: "report",
    param: "report",
    title: "数据中心",
    image: `${envAPI.assets}/app-report.svg`,
    menuTitle: "数据中心",
    description: "从数据图表中获取信息",
    descLocaleKey: "report描述",
    href: `${APP_BASE_URLS.homeUrl}/data-center?locale=${cloud_locale}`,
  },
  // 开发者
  openAPI: {
    key: "openAPI",
    param: "openAPI",
    title: "开发者中心",
    image: `${envAPI.assets}/app-openAPI.svg`,
    menuTitle: "开发者中心",
    description: "开发者中心",
    descLocaleKey: "openAPI描述",
    href: `${APP_BASE_URLS.openAPI}`,
  },
  // 物联网平台
  iot: {
    key: "iot",
    param: "iot",
    title: "物联网平台",
    image: `${envAPI.assets}/app-iot.svg`,
    menuTitle: "物联网平台",
    description: "物联网平台",
    descLocaleKey: "iot描述",
    href: `${APP_BASE_URLS.homeUrl}/iot`,
  },
  // 数据中心管理
  biManage: {
    key: "biManage",
    param: "biManage",
    title: "数据中心管理",
    image: `${envAPI.assets}/app-biCenter.svg`,
    menuTitle: "数据中心管理",
    description: "数据中心后台配置",
    descLocaleKey: "biManage描述",
    href: `${APP_BASE_URLS.homeUrl}/partner-report`,
  },
  cleanLibrary: {
    key: "cleanLibrary",
    param: "cleanLibrary",
    title: "标准工作项库",
    image: cleanImage,
    menuTitle: "标准工作项库",
    description: "标准工作项库管理",
    descLocaleKey: "cleanLibrary描述",
    href: `${APP_BASE_URLS.homeUrl}/clean-library`,
  },
  hwordGroup: {
    key: "hwordGroup",
    param: "hwordGroup",
    title: "华住注册",
    image: hwordGroupImage,
    menuTitle: "华住注册",
    description: "华住注册",
    descLocaleKey: "hwordGroup描述",
    href: `${APP_BASE_URLS.homeUrl}/hword-group`,
  },
  factoryManagement: {
    key: "factoryManagement",
    param: "factoryManagement",
    title: "工厂信息管理",
    image: factoryManagement,
    menuTitle: "工厂信息管理",
    description: "工厂信息管理",
    descLocaleKey: "工厂信息管理",
    href: `${APP_BASE_URLS.homeUrl}/factory-management`,
  },
  beep: {
    key: "beep",
    param: "beep",
    title: "基础数据管理",
    image: beep,
    menuTitle: "基础数据管理",
    description: "基础数据管理",
    descLocaleKey: "基础数据管理",
    href: `${APP_BASE_URLS.homeUrl}/beep`,
  },
  reportCentre: {
    key: "reportCentre",
    param: "reportCentre",
    title: "报表中心",
    image: reportCentre,
    menuTitle: "报表中心",
    description: "报表中心",
    descLocaleKey: "报表中心",
    href: `${APP_BASE_URLS.homeUrl}/report-centre`,
  },
  equipmentImport: {
    key: "equipmentImport",
    param: "equipmentImport",
    title: "设备导入管理",
    image: cleanImage,
    menuTitle: "设备导入管理",
    description: "设备导入管理",
    descLocaleKey: "设备导入管理",
    href: `${APP_BASE_URLS.homeUrl}/equipment-import`,
  },
  // 运维运营
  operationMaintenance: {
    key: "operationMaintenance",
    param: "operationMaintenance",
    title: "运维运营",
    image: operationMaintenance,
    menuTitle: "运维运营",
    description: "运维运营",
    descLocaleKey: "运维运营",
    href: `${APP_BASE_URLS.homeUrl}/operation-maintenance`,
  },
  parameterFlowManagement: {
    key: "parameterFlowManagement",
    param: "parameterFlowManagement",
    title: "机器分析与管控",
    image: parameterFlowManagement,
    menuTitle: "机器分析与管控",
    description: "机器分析与管控",
    descLocaleKey: "机器分析与管控",
    href: `${APP_BASE_URLS.homeUrl}/parameter-flow-management`,
  },
};

/**
 * @Region 区域
 * (-- 1 ~ 99 is reserved for future usage --)
 * (-- MAYBE OUR OWN DATA CENTER --)
 * (-- 100 ~ 199 is reserved for Aliyun --)
 * 100X is for 华北
 * 101X is for 华东
 * (-- 2000 ～ 2999 is reserved for AWS --)
 * 200X is for us-east
 * 201X is for us-west
 * 205X is for ap-northeast
 * 206X is for ap-southeast
 * 208X is for eu-central
 * 209X is for eu-west
 */
const REGIONS = REGION ? [
  {
    code: "1",
    lowerValue: "china",
    value: "CHINA",
    title: "欧盟地区", // China
  },
] : [
  {
    code: "1",
    lowerValue: "china",
    value: "CHINA",
    title: "中国", // China
  },
  {
    code: "2",
    lowerValue: "us-east",
    value: "US_EAST",
    title: "美国东部", // US East (N. Virginia)
  },
  {
    code: "3",
    lowerValue: "us-west",
    value: "US_WEST",
    title: "美国西部", // US West (Oregon)
  },
  {
    code: "4",
    lowerValue: "asia-pacific-northeast",
    value: "ASIA_PACIFIC_NORTHEAST",
    title: "亚太东京", // Asia Pacific (Tokyo)
  },
  {
    code: "5",
    lowerValue: "asia-pacific-southeast",
    value: "ASIA_PACIFIC_SOUTHEAST",
    title: "亚太新加坡", // Asia Pacific (Singapore)
  },
  {
    code: "6",
    lowerValue: "european-union",
    value: "EUROPEAN_UNION",
    title: "欧盟地区", // European Union
  },
  {
    code: "7",
    lowerValue: "united-kingdom",
    value: "UNITED_KINGDOM",
    title: "英国", // United Kingdom
  },
];
const REGIONS_OLD = [
  {
    code: "1010",
    lowerValue: "ali-cn-hangzhou",
    value: "ALI_CN_HANGZHOU",
    title: "杭州",
  },
  {
    code: "1011",
    lowerValue: "ali-cn-shanghai",
    value: "ALI_CN_SHANGHAI",
    title: "上海",
  },
  {
    code: "2000",
    lowerValue: "aws-us-east-1",
    value: "AWS_US_EAST_1",
    title: "美国东部", // US East (N. Virginia)
  },
  {
    code: "2011",
    lowerValue: "aws-us-west-2",
    value: "AWS_US_WEST_2",
    title: "美国西部", // US West (Oregon)
  },
  {
    code: "2050",
    lowerValue: "aws-ap-northeast-1",
    value: "AWS_AP_NORTHEAST_1",
    title: "亚太东京", // Asia Pacific (Tokyo)
  },
  {
    code: "2060",
    lowerValue: "aws-ap-southeast-1",
    value: "AWS_AP_SOUTHEAST_1",
    title: "亚太新加坡", // Asia Pacific (Singapore)
  },
  {
    code: "2080",
    lowerValue: "aws-eu-central-1",
    value: "AWS_EU_CENTRAL_1",
    title: "欧洲法兰克福", // Europe (Frankfurt)
  },
  {
    code: "2091",
    lowerValue: "aws-eu-west-2",
    value: "AWS_EU_WEST_2",
    title: "欧盟西部", // EU West
  },
];

/**
 * @Language 用户语言
 * 兼容旧系统
 */
const PERSON_LANG = [
  {
    title: "简体中文",
    value: "cn",
  },
  {
    title: "English",
    value: "en",
  },
  {
    title: "한국어",
    value: "ko",
  },
  {
    title: "Polski",
    value: "pl",
  },
];
/**
 * @Language 用户语言
 */
const STANDARD_LANG_CODE = [
  {
    title: "简体中文",
    value: "zh-CN",
  },
  {
    title: "English",
    value: "en-US",
  },
  {
    title: "한국어",
    value: "ko-KR",
  },
  {
    title: "Polski",
    value: "pl-PL",
  },
  {
    title: "日本語",
    value: "ja-JP",
  },
  {
    title: "Français",
    value: "fr-FR",
  },
  {
    title: "Deutsch",
    value: "de-DE",
  },
  {
    title: "nederlands",
    value: "nl-NL",
  },
  {
    title: "Suomi",
    value: "fi-FI",
  },
  {
    title: "svenska",
    value: "sv-SE",
  },
]
const PERSON_LANG_NEW = [
  {
    title: "简体中文",
    value: "zh-CN",
  },
  {
    title: "English",
    value: "en",
  },
  {
    title: "한국어",
    value: "ko",
  },
  {
    title: "Polski",
    value: "pl",
  },
  {
    title: "日本語",
    value: "ja-JP",
  },
  {
    title: "Français",
    value: "fr-FR",
  },
  {
    title: "Deutsch",
    value: "de-DE",
  },
  {
    title: "Nederlands",
    value: "nl-NL",
  },
  {
    title: "Suomi",
    value: "fi-FI",
  },
  {
    title: "Svenska",
    value: "sv-SE",
  },
];
const PERSON_LANG_OBJ = {
  "ja-JP": "日本語",
  "zh-CN": "简体中文",
  en: "English",
  ko: "한국어",
  pl: "Polski",
  "ja-JP": "日本語",
  "fr-FR": "Français",
};
/**
 * @时间格式
 */
const DATE_DAY_FORMAT = "YYYY-MM-DD";
const DATE_FORMAT = "YYYY-MM-DD HH:mm:ss";
const DATE_UTC_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

/**
 * @性别
 */
const GENDER = [
  { key: 1, title: "男", value: "MALE" },
  { key: 2, title: "女", value: "FEMALE" },
  { key: 3, title: "保密", value: "PRIVACY" },
];

const SECONDARY_LOGIN_AUTHENTICATION = [
  { key: 1, title: "开启", value: true },
  { key: 2, title: "停用", value: false },
]

/**
 * @客户类型
 * 代理商: AGENT, 终端客户: CLIENT
 */
const COMMON_CUSTOMER_TYPE = [
  { title: "代理商", value: "AGENT" },
  { title: "终端客户", value: "CLIENT" },
]

/**
 * @客户类型
 * 代理商: AGENT, 终端客户: CLIENT, 租赁: TENANCY, 运营商: OPERATOR
 */
const CUSTOMER_TYPE = [
  ...COMMON_CUSTOMER_TYPE,
  { title: "服务商", value: "SERVICER" },
  // { title: '租赁', value: 'TENANCY' },
  // { title: '运营商', value: 'OPERATOR' },
];

export const ROBOT_STATES = [
  { title: "NORMAL", value: "NORMAL" },
  { title: "RETIRED", value: "RETIRED" },
];

const CUSTOMER_TYPE_OBJ = {
  AGENT: "代理商",
  CLIENT: "终端客户",
  TENANCY: "租赁",
  OPERATOR: "运营商",
  SERVICER: "服务商",
};

/**
 * @客户级别
 */
const VIP_LEVEL = [
  { title: "否", value: 0 },
  { title: "是", value: 1 },
];
const VIP_LEVEL_OBJ = {
  0: "否",
  1: "是",
};

/**
 * @运维大区
 */
const MAINTENANCE_REGIONS = [
  {
    id: "1",
    name: "maintenanceRegions/1",
    displayName: "华东",
  },
  {
    id: "2",
    name: "maintenanceRegions/2",
    displayName: "华南",
  },
  {
    id: "3",
    name: "maintenanceRegions/3",
    displayName: "华北",
  },
  {
    id: "4",
    name: "maintenanceRegions/4",
    displayName: "海外",
  },
  {
    id: "5",
    name: "maintenanceRegions/5",
    displayName: "公共",
  },
  {
    id: "6",
    name: "maintenanceRegions/6",
    displayName: "其他",
  },
  {
    id: "7",
    name: "maintenanceRegions/7",
    displayName: "欧美",
  },
  {
    id: "8",
    name: "maintenanceRegions/8",
    displayName: "亚太",
  },
  {
    id: "9",
    name: "maintenanceRegions/9",
    displayName: "华中",
  },
  {
    id: "10",
    name: "maintenanceRegions/10",
    displayName: "西部",
  },
];

/**
 * @租户类型
 * 租户: TENANT, 托管: TRUST
 */
const TENANT_TYPE = [
  { title: "租户", value: 0 },
  { title: "托管", value: 1 },
];
const TENANT_TYPE_OBJ = {
  0: "租户",
  1: "托管",
};

/**
 * @组织类型
 **/
const ORG_TYPE = [
  { title: "租户", value: "TENANT" },
  { title: "托管", value: "MANAGED" },
  { title: "普通", value: "NORMAL" },
];
const ORG_TYPE_NEW = [
  { title: "终端项目", value: "CLIENT" },
  { title: "普通", value: "NORMAL" },
  { title: "公司", value: "CUSTOMER" },
];

const ORG_TYPE_OBJ = {
  TENANT: "租户",
  MANAGED: "托管",
  NORMAL: "普通",
};

/**
 * @状态 通用
 **/
const COMMON_STATE = [
  { title: "启用", value: "ENABLED" },
  { title: "停用", value: "DISABLED" },
];

const COMMON_STATE_OBJ = {
  ENABLED: "启用",
  DISABLED: "停用",
};

/**
 * @组织树 深度
 * 组织结构深度不超过8级
 **/
const ORG_DEEP_LEN = 8;

/**
 * @机器人
 *
 **/
const ROBOT_RIGHT_OBJ = {
  OWNERSHIP: "使用权",
  OPERATION: "运维权",
};

export {
  COPYRIGHT,
  SERVICES,
  REGIONS,
  REGIONS_OLD,
  DATE_DAY_FORMAT,
  DATE_FORMAT,
  DATE_UTC_FORMAT,
  PERSON_LANG,
  STANDARD_LANG_CODE,
  PERSON_LANG_NEW,
  PERSON_LANG_OBJ,
  GENDER,
  COMMON_CUSTOMER_TYPE,
  CUSTOMER_TYPE,
  CUSTOMER_TYPE_OBJ,
  VIP_LEVEL,
  VIP_LEVEL_OBJ,
  MAINTENANCE_REGIONS,
  TENANT_TYPE,
  TENANT_TYPE_OBJ,
  ORG_TYPE,
  ORG_TYPE_OBJ,
  ORG_TYPE_NEW,
  COMMON_STATE,
  COMMON_STATE_OBJ,
  ORG_DEEP_LEN,
  ROBOT_RIGHT_OBJ,
  CURRENT_YEAR,
  SECONDARY_LOGIN_AUTHENTICATION
};
