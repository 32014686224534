// 异步加载组件
import { asyncComponent } from 'react-async-component';

// import APPHome from "./APPHome";
// import AppsFrame from "./AppsFrame";
const APPHome = asyncComponent({resolve: () => import("./APPHome")});
const AppsFrame = asyncComponent({resolve: () => import("./AppsFrame")});

const routers = [
  // {
  //   path: '/',
  //   component: APPHome,
  //   exact: true
  // },
  {
    path: '/app/center/home',
    component: APPHome
  },
  {
    path: '/app/center/:serviceName',
    component: AppsFrame
  },
];

export default routers;