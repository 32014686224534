/**
 * @隐私协议
 */
 import React, { useContext, useState, useEffect }  from "react";
 import { GlobalContext } from "src/view/reducer";
 import { getLocale } from "src/libs/utils";
 import { Login } from "src/api/Auths";
 import MarkDownRender from "./MarkDownRender";
 /**
  * component start
  */
 const PrivacyAgreement = () => {
   const loginApi = new Login();
   const { userLocale } = useContext(GlobalContext);
 
   const [url, setUrl] = useState(null);
 
   // 获取隐私协议 版本信息
   const getInfo = () => {
     loginApi.getPrivacyAgreementInfo().then(res => {
       const { urls, version } = res;
       const locale = userLocale || getLocale();
       if (locale === 'cn' || locale === 'zh-CN') {
        setUrl(urls['zh-CN'])
      } else if (locale === 'en' || locale === 'en-US') {
        setUrl(urls['en-US'])
      } else {
        // 上两个分支特殊处理，后续新增的语言，均走标准的语言映射，未找到映射时英文兜底
        setUrl(urls[locale] || urls['en-US'])
      }
     });
   };
 
   useEffect(() => {
    getInfo()
   }, []);
 
   return <MarkDownRender url={url}/>
 };
 
 export default PrivacyAgreement;