/**
 * @author tiandd
 * @module 常用 window.[local|session]Storage 封装
 * @cause 原生太长..
 * @date 2021/07/30
 */
const $session = window.sessionStorage;
const $local = window.localStorage;

class Storage {
  constructor() {
    this.Storage = $session;
    this.local = $local;
  }
  sessionget(key) {
    let value = this.Storage.getItem(key);
    // 简单校验是字符串对象
    if (/(^{[\s\S]*}$)|(^\[[\s\S]*\]$)/.test(value)) {
      value = JSON.parse(value);
    }
    return value;
  }
  sessionset(key, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    this.Storage.setItem(key, value);
  }
  remove(key) {
    this.Storage.removeItem(key);
    this.local.removeItem(key);
  }
  get(key) {
    let value = this.local.getItem(key);
    // 简单校验是字符串对象
    if (/(^{[\s\S]*}$)|(^\[[\s\S]*\]$)/.test(value)) {
      value = JSON.parse(value);
    }
    return value;
  }
  set(key, value) {
    if (typeof value === 'object') {
      value = JSON.stringify(value);
    }
    this.local.setItem(key, value);
  }
}

export default new Storage();
