import React, { useEffect, useState } from "react";
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import { httpNoToken } from "src/api/Auths";
import './style.scss';

const MarkDownRender = props => {
  const [source, setSource] = useState(null);

  useEffect(() => {
    if (props.url) {
      httpNoToken.get(props.url).then(res => {
        setSource(res)
      })
    }
  }, [props.url])
  
  return (
    <div className="md-container">
      <ReactMarkdown
        children={source}
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
      />
    </div>
  )
}

export default MarkDownRender;