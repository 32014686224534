import { useEffect, useState, useRef } from 'react';
import { Button, Descriptions, Card, Tabs, Table } from 'antd';
import moment from 'moment';
import { useParams } from 'react-router-dom';
import RouteBreadcrumb from "src/component/RouteBreadcrumb";
import { machineAuthorizationApi } from '../_api';
import { GREEN_APPLY_STATUS, RED_APPLY_STATUS } from 'src/constants';
import { dateFormat } from "src/libs/utils";

const DescLabel = props => {
    return <span className="font-grey-l2">{props.title}</span>
}

const Detail = (props) => {
    const { applyId } = useParams();
    const [detailInfo, setDetailInfo] = useState({});
    const [detailList, setDetailList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [total, setTotal] = useState(0);

    const initialPaginantion = {
        pageNumber: 1,
        pageSize: 10,
    };

    const paginationRef = useRef({ ...initialPaginantion });
    
    useEffect(() => {
        if (applyId) {
            fetchDetailInfo(applyId);
            fetchDetailLsit();
        }
    }, [applyId]);
    

    const fetchDetailInfo = async (applyId) => {
        const resp = await machineAuthorizationApi.singleAuthApplyDetail({
            applyId
        });
        setDetailInfo(resp?.data?.apply)
    }

    const fetchDetailLsit = async () => {
        setLoading(true);
        try {
            const resp = await machineAuthorizationApi.authApplyLogs({
                applyId,
                ...paginationRef.current,
                excludeApplyStates: ["SYSTEM_APPROVED","SYSTEM_INTERCEPTED"]
            });
            setDetailList(resp?.data?.logs);
            setTotal(resp?.data?.totalSize)
        } finally {
            setLoading(false)
        }
  
    }

    const onTableChange = ({ current, pageSize }) => {
        paginationRef.current = {
            pageNumber: current,
            pageSize,
        };
        fetchDetailLsit();
    };

    const breadCrumb = [
        {
            path: "",
            breadcrumbName: lang.授权管理,
        },
        {
            path: "/app/approval-center/home",
            breadcrumbName: lang.审批中心,
        },
        {
            path: "",
            breadcrumbName: lang.详情,
        },
    ]

    const columns = [
        {
            title: lang.处理类型,
            dataIndex: 'applyState',
            render: (applyState) => {
                if (GREEN_APPLY_STATUS.includes(applyState)) {
                    return lang.同意授权
                }

                if (RED_APPLY_STATUS.includes(applyState)) {
                    return lang.拒绝授权
                }

                return lang.审批中
            }

        },
        {
            title: lang.处理评论,
            dataIndex: 'auditReason',
            render: (auditReason) => auditReason || '-'
        },
        {
            title: lang.处理时间,
            dataIndex: 'createTime',
            render: (createTime) => dateFormat.formatUnixTimeStamp(createTime)
        },
    ]


    return <div>
        <RouteBreadcrumb routes={breadCrumb}></RouteBreadcrumb>
        <Card bordered>
            <div style={{ fontSize: 24, fontWeight: 500, marginBottom: 16 }}>{lang.申请账号}: {detailInfo?.applyPersonCustomerName}</div>
            <Descriptions column={3}>
                <Descriptions.Item label={<DescLabel title={lang?.申请人所属客户} />}>
                    {detailInfo?.applyPersonCustomerName}
                </Descriptions.Item>
                <Descriptions.Item label={<DescLabel title={lang?.机器SN} />}>
                    {detailInfo?.robotSn}
                </Descriptions.Item>
                <Descriptions.Item label={<DescLabel title={lang?.机器所属客户} />}>
                    {detailInfo?.robotOwnerCustomerName}
                </Descriptions.Item>
                <Descriptions.Item label={<DescLabel title={lang?.申请时间} />}>
                    {detailInfo?.applyTime ? dateFormat.formatUnixTimeStamp(detailInfo?.applyTime) : '-'}
                </Descriptions.Item>
                <Descriptions.Item label={<DescLabel title={lang?.申请原因} />}>
                    {detailInfo?.applyReason}
                </Descriptions.Item>
            </Descriptions>
        </Card>
        <Card bordered style={{ marginTop: 16 }}>
            <Tabs>
                <Tabs.TabPane tab={lang.处理记录}>
                    <Table
                      columns={columns}
                      dataSource={detailList}
                      loading={loading}
                      onChange={onTableChange}
                      pagination={{
                          current: paginationRef.current.pageNumber,
                          pageSize: paginationRef.current.pageSize,
                          total
                      }}
                    >

                    </Table>
                </Tabs.TabPane>
            </Tabs>
        </Card>
    </div>
}

export default Detail;