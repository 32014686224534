import { getLocale } from "../libs/utils";

// 语言初始化
export const initI18n = async () => {
  const locale = getLocale();
  const getCN = () => import('../locales/zh-CN.json');
  const getEN = () => import('../locales/en-US.json');
  const getJP = () => import('../locales/ja-JP.json');
  window['lang'] = {};

  switch (locale) {
    case 'zh-CN':
      {
        const res = await getCN();
        window['lang'] = res;
      }
      break;

    case 'ja-JP':
      {
        const res = await getJP();
        window['lang'] = res;
      }
      break;
  
    default:
      {
        const res = await getEN();
        window['lang'] = res;
      }
      break;
  }
  return Promise.resolve(true);
};

// 带参数的
window['__lang'] = function (key, arr, confArr) {
  var value = window['lang'][key];
  if (!value) {
      return '';
  } else {
      if (!Array.isArray(arr)) {
          arr = [arr];
      }
      if (!confArr) {
          confArr = [{}];
      } else if (!Array.isArray(confArr)) {
          confArr = [confArr];
      }
      return value.replace(/\{(\d+)\}/g, function(match, $1) { return arr[$1]; })
      .replace(/\#(\d+)\#/g, function(match, $1) { 
        var className = (confArr[$1] && confArr[$1].className) ? confArr[$1].className : '';
        var label = (confArr[$1] && confArr[$1].label) ? confArr[$1].label : 'span';
        return `<${label} class="${className}">${arr[$1]}</${label}>`; 
    });
  }
};

export const switchLocale = langValue => {
  switch (langValue) {
    case 'zh-CN':
      localStorage.setItem('locale', 'zh-CN');
      break;

    case 'en':
      localStorage.setItem('locale', 'en');
      break;

    case 'ko':
      localStorage.setItem('locale', 'ko');
      break;

    case 'pl':
      localStorage.setItem('locale', 'pl');
      break;

    case 'ja-JP':
      localStorage.setItem('locale', 'ja-JP');
      break;

    default:
      localStorage.setItem('locale', 'en');
      break;
  }

  if (window) {
    window.location.reload();
  }
};

export const i18n = key => {

  try {
    // let value = appLocale;
    const _value = lang[key];
    if (!_value) {
      return null;
    } else {
      return _value;
    }

  } catch (err) {
    // throw Error(`i18n: key ${key} invalid: ${err}`);
    // console.log(`i18n: key ${key} invalid: ${err}`);
  }
};

