import React, { createContext, useReducer, useState } from "react";

const initState = {
  lang: {},
  user: {},                           // 当前登录用户的信息
  tenant: {},                         // 当前登录用户的所属租户信息
  customer: {},                       // 当前用户所在公司（客户）
  roles: [],                          // 当前登录用的角色列表
  rolesForInvite: [],                 // 角色列表：用于邀请用户
  privacyMoveParams: {},               // 隐私数据迁移
  isCurrentUserAdmin: false,           // 当前用户是否为管理员
  activeMenu: localStorage.getItem('layout.menu.active') || 'app_center',            // 当前选中的菜单
  registerInfo: {},                     // 用户注册信息
  userLocale: '', // 用户语言
  bindInfo: '', // 绑定信息
  selectedTenant: '', // 切换租户，选择的租户信息
  authTenants: JSON.parse(sessionStorage.getItem('view.layout.auth.tenants')) || null, // 授权的租户
  velidateRules: {}, // 验证规则
  access: {}, // 权限
  regionsV3: [], // 运维大区新版接口数据
  regionsV2: [], // 运维大区新旧兼容
  isGroupCompany: false, // 当前账号是否是公司集团,
  internalPerson: false, // 当前账号是否是内部组织,
  applicationPermissions: [], // 当前登录用户的应用权限
}

// 全局Context
export const GlobalContext = createContext(null);

// reducer
const reducer = (state, action) => {
  switch (action.type) {
    // 更新语言包：未使用
    case 'UPDATE_LANG':
      return { ...state, lang: action.data };

    // 更新当前用户默认语言
    case 'UPDATE_USER_LOCALE':
      return { ...state, userLocale: action.data };

    // 更新当前登录 user 信息
    case 'UPDATE_CURRENT_USER':
      return { ...state, user: action.data };

    // 更新当前登录 tenant 信息
    case 'UPDATE_CURRENT_TENANT':
      return { ...state, tenant: action.data };

    // 更新当前登录 roles 信息
    case 'UPDATE_CURRENT_ROLES':
      return { ...state, roles: action.data };

    // 更新当前登录 应用权限信息
    case 'UPDATE_APPLICATION_PERMISSIONS':
      return {...state, applicationPermissions: action.data };

    // 更新邀请用户的可用角色 roles 信息
    case 'UPDATE_ROLES_FOR_INVITE':
      return { ...state, rolesForInvite: action.data };

    // 更新隐私数据迁移 参数
    case 'UPDATE_PRIVACY_MOVE_PARAMS':
      return { ...state, privacyMoveParams: action.data };

    // // 当前用户是否为管理员
    case 'UPDATE_CURRENT_USER_IS_ADMIN':
      return { ...state, isCurrentUserAdmin: action.data };

    // 更新当前选中的菜单
    case 'UPDATE_ACTIVE_MENU':
      return { ...state, activeMenu: action.data };

    // 更新用户注册信息
    case 'UPDATE_REGISTER_INFO':
      return { ...state, registerInfo: action.data };

    // 更新绑定信息
    case 'UPDATE_BINDING_INFO':
      return { ...state, bindInfo: action.data };

    // 更新切换租户
    case 'UPDATE_SELECTED_TENANT':
      return { ...state, selectedTenant: action.data };

    // 授权的租户
    case 'UPDATE_AUTH_TENANTS':
      return { ...state, authTenants: action.data };

    // 更新验证规则
    case 'UPDATE_VELIDATE_RULES':
      return { ...state, velidateRules: action.data };

    // 更新权限
    case 'UPDATE_ACCESS':
      return { ...state, access: action.data };

    // 更新运维大区：新版
    case 'UPDATE_REGION_V3':
      return { ...state, regionsV3: action.data };

    // 更新运维大区：新版 兼容
    case 'UPDATE_REGION_V2':
      return { ...state, regionsV2: action.data };

    // 更新集团视图：当前tenant是否为公司集团
    case 'UPDATE_CUSTOMER':
      return { ...state, customer: action.data };

    // 更新账号内部组织标志
    case 'UPDATE_INTERNAL_PERSON':
      return { ...state, internalPerson: action.data };

    default:
      break;
  }
};

export const Common = props => {
  // user={user} tenant={tenant} roles={roles}
  const [state, dispath] = useReducer(reducer, { ...initState });

  return (
    <GlobalContext.Provider value={{
      ...state,
      dispath
    }}>
      {props.children}
    </GlobalContext.Provider>
  );
};