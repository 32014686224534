export const ERROR_CODE_MAP = {
    33102: '该客户编码已经在IAM中存在，不能重复填写',
    33103: 'UDESK中无该客户编码，请清除后重新填写',
    33104: '系统繁忙，请稍后再试！',

    33040: '申请原因超过长度限制',
    33041: '存在信息变更，请重新填写！',
    33042: '您填写的信息不正确，请重新填写！',
    33043: '已绑定，无需再次申请！',
    33050: '无权申请！',
    33052: '当前账号，最多仅支持绑定200台机器！',
    33053: 'SN不存在',
    33054: '新增和删除列表不能为空',
    33055: '新增和删除列表存在交集',
    33057: '存在申请人账号被注销，操作失败',
    33205: '用户不存在!',
    33209: '用户已禁用,无法继续操作！',
    33059: 'SN格式不正确',   
    33060: '用户所属客户不存在!',
    33061: '用户所属客户被禁用,无法继续操作!',
    33063: '存在信息变更，请重新填写！',
}

export const REASON_CODE_MAP = {
    'CRM_CUSTOMER_NO_NOT_FOUND': 'UDESK中无该客户编码，请清除后重新填写',
    'UDESK_CUSTOMER_CHANGED_AND_NAME_UNIQUE': '系统已同步UDESK最新值，请再次确认',
    'UDESK_CUSTOMER_CHANGED_AND_NAME_NOT_UNIQUE': '客户名称已存在',
    'CRM_CUSTOMER_NO_EXISTED': '编号已存在',
    'CUSTOMER_NAME_EXISTED': '客户名称已存在'
}