import React from 'react';
import OutlinkLayout from "../layout/OutlinkLayout";
import PrivacyAgreement from "../component/agreements/PrivacyAgreement";
import UserAgreement from "../component/agreements/UserAgreement";

const Agreements = () => {
  const layout = {
    width: '1000px',
    margin: '0 auto',
    background: "#fff",
    padding: "30px"
  }
  return (
    <OutlinkLayout>
      <div style={layout}>
        <UserAgreement />
        <br/>
        <PrivacyAgreement />
      </div>
    </OutlinkLayout>
  );
};

export default Agreements;